import React from 'react';
import { AccountType } from '../common/enums/account/account-type.enum';
import {
  ForgotPasswordUrl,
  LoginUrl,
  NotFoundUrl,
  ResetPasswordUrl,
  CompleteRegisrationUrl,
  DashboardUrl,
  AccountsUrl,
  AffiliatesUrl,
  MyAgentsUrl,
  LeadsUrl,
  LeadsImportUrl,
  SettingsUrl,
  UncategorizedLeadsUrl,
  FoldersUrl,
  FolderDetailsUrl,
  ReportsUrl,
} from '../UrlsConfig';

const NotFoundComponent = React.lazy(() => import('../pages/NotFound'));
const LoginComponent = React.lazy(() => import('../pages/Login'));
const ForgotPasswordComponent = React.lazy(
  () => import('../pages/ForgotPassword'),
);
const PasswordRecoveryComponent = React.lazy(
  () => import('../pages/PasswordRecovery'),
);
const DashboardComponent = React.lazy(() => import('../pages/Dashboard'));
const AccountsComponent = React.lazy(() => import('../pages/Accounts'));
const LeadsComponent = React.lazy(() => import('../pages/Leads'));
const LeadsImportComponent = React.lazy(() => import('../pages/LeadsImport'));
const SettingsComponent = React.lazy(() => import('../pages/Settings'));
const FoldersComponent = React.lazy(() => import('../pages/Folders'));
const FolderDetailsComponent = React.lazy(
  () => import('../pages/FolderDetails'),
);
const ReportsComponent = React.lazy(() => import('../pages/Reports'));

const authorizedRoutePart = [
  {
    path: DashboardUrl,
    component: DashboardComponent,
    role: [
      AccountType.Admin,
      AccountType.MainAccount,
      AccountType.SubAccount,
      AccountType.Agent,
    ],
  },
  {
    path: NotFoundUrl,
    component: NotFoundComponent,
    role: [],
  },
  {
    path: AccountsUrl,
    component: AccountsComponent,
    role: [AccountType.Admin],
  },
  {
    path: AffiliatesUrl,
    component: AccountsComponent,
    role: [AccountType.MainAccount],
  },
  {
    path: MyAgentsUrl,
    component: AccountsComponent,
    role: [AccountType.SubAccount, AccountType.MainAccount],
  },
  {
    path: LeadsUrl,
    component: LeadsComponent,
    role: [
      AccountType.Admin,
      AccountType.MainAccount,
      AccountType.SubAccount,
      AccountType.Agent,
      AccountType.Restricted,
    ],
  },
  {
    path: UncategorizedLeadsUrl,
    component: LeadsComponent,
    role: [],
  },
  {
    path: LeadsImportUrl,
    component: LeadsImportComponent,
    role: [AccountType.Admin, AccountType.MainAccount],
  },
  {
    path: SettingsUrl,
    component: SettingsComponent,
    role: [AccountType.Admin],
  },
  {
    path: FoldersUrl,
    component: FoldersComponent,
    role: [],
  },
  {
    path: FolderDetailsUrl,
    component: FolderDetailsComponent,
    role: [],
  },
  {
    path: ReportsUrl,
    component: ReportsComponent,
    role: [AccountType.MainAccount],
  },
];

const unauthorizedRoutePart = [
  {
    path: LoginUrl,
    component: LoginComponent,
    role: [],
  },
  {
    path: ForgotPasswordUrl,
    component: ForgotPasswordComponent,
    role: [],
  },
  {
    path: ResetPasswordUrl,
    component: PasswordRecoveryComponent,
    role: [],
  },
  {
    path: CompleteRegisrationUrl,
    component: PasswordRecoveryComponent,
    role: [],
  },
];

export const RouteConfig = {
  authList: authorizedRoutePart,
  notAuthList: unauthorizedRoutePart,
};
