export enum LeadRoutes {
  BasePrefix = 'leads',
  TemplateToken = 'template/token',
  Template = 'template',
  Uploads = 'uploads',
  UploadAttachments = ':leadId/attachment',
  GetUploadAttachmentsLink = 'attachments',
  GetUploadAttachmentsStatus = 'attachments/status',
  AssignAttachments = 'attachments/assign',
  GetDownloadAttachmentLink = ':leadId/attachment/link',
  GetDownloadBulkUploadErrorsLink = ':bulkUploadId/attachment/errors-link',
  UploadId = ':uploadId',
  BulkUploadId = ':bulkUploadId',
  ById = ':leadId',
  Export = 'export',
  ExportPdfPreview = 'export/preview',
  ExportToken = 'export/token',
  GetStatisticalInfoByStates = 'stats/states',
  GetStatisticalInfoBySpecificStates = 'stats/states/:stateAbbr',
  Reports = 'reports',
  Stats = 'stats',
  Affiliates = 'affiliates',
  Agents = 'agents',
  States = 'states',
  GetLeadsReceivedStatisticalInfo = 'dashboard/received',
  GetLeadsMetricsInfo = "dashboard/metrics",
  GetTopLeadsInfo = 'dashboard/top',
  CsvErrorsToken = 'upload-errors/token',
}
