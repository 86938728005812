import { EMessageType } from '../../service/enums';
import { TranslationResult } from '../../service/types';
import { AccountErrorCodes } from '../../common/enums/error-codes/account-error-codes.enum';
import { AccountTypeErrorCodes } from '../../common/enums/error-codes/account-type-error-codes.enum';
import { AuthErrorCodes } from '../../common/enums/error-codes/auth-error-codes.enum';
import { CommonErrorCodes } from '../../common/enums/error-codes/common-error-codes.enum';
import { SortAndSearchErrorCodes } from '../../common/enums/error-codes/sort-and-search-error-codes.enum';
import { TokenErrorCodes } from '../../common/enums/error-codes/token-error-codes.enum';
import { i18NParser } from '../utils';
import { LeadErrorCodes } from '../../common/enums/error-codes/lead-error.codes.enum';
import { FolderErrorCodes } from '../../common/enums/error-codes/folder-error-codes.enum';
import { UploadedLeadErrorCodes } from '../../common/enums/error-codes/uploaded-lead-error-codes.enum';

export const translation: Record<
  EMessageType | 'field_validation',
  Record<string, TranslationResult | string>
> = {
  error: {
    [AccountErrorCodes.AccountNotActive]: {
      title: i18NParser(AccountErrorCodes.AccountNotActive),
    },
    [AccountErrorCodes.AccountNotFound]: {
      title: 'Invalid login or password',
      message: 'Please ensure you enter the correct information and try again.',
    },
    [AccountErrorCodes.AccountWithEmailAlreadyExist]: {
      title: 'Account with this email already exists',
    },
    [AccountErrorCodes.InvitationLinkAlreadyUsed]: {
      title: i18NParser(AccountErrorCodes.InvitationLinkAlreadyUsed),
    },
    [AccountErrorCodes.AccountAlreadyActivated]: {
      title: i18NParser(AccountErrorCodes.AccountAlreadyActivated),
    },
    [AccountErrorCodes.InvalidAccountStatus]: {
      title: i18NParser(AccountErrorCodes.InvalidAccountStatus),
    },
    [AccountTypeErrorCodes.AccountTypeNotFound]: {
      title: i18NParser(AccountTypeErrorCodes.AccountTypeNotFound),
    },
    [AuthErrorCodes.InvalidPassword]: {
      title: 'Invalid login or password',
      message: 'Please ensure you enter the correct information and try again.',
    },
    [CommonErrorCodes.Unauthorized]: {
      title: i18NParser(CommonErrorCodes.Unauthorized),
    },
    [CommonErrorCodes.Forbidden]: {
      title: i18NParser(CommonErrorCodes.Forbidden),
    },
    [CommonErrorCodes.FieldIsInvalid]: {
      title: i18NParser(CommonErrorCodes.FieldIsInvalid),
    },
    [CommonErrorCodes.InternalServerError]: {
      title: i18NParser(CommonErrorCodes.InternalServerError),
    },
    [SortAndSearchErrorCodes.InvalidSortProperty]: {
      title: i18NParser(SortAndSearchErrorCodes.InvalidSortProperty),
    },
    [SortAndSearchErrorCodes.InvalidSearchProperty]: {
      title: i18NParser(SortAndSearchErrorCodes.InvalidSearchProperty),
    },
    [TokenErrorCodes.ProblemWithInviteToken]: {
      title: i18NParser(TokenErrorCodes.ProblemWithInviteToken),
    },
    [TokenErrorCodes.ProblemWithAccessToken]: {
      title: i18NParser(TokenErrorCodes.ProblemWithAccessToken),
    },
    [TokenErrorCodes.ProblemWithRefreshToken]: {
      title: i18NParser(TokenErrorCodes.ProblemWithRefreshToken),
    },
    [TokenErrorCodes.ProblemWithResetPasswordToken]: {
      title: i18NParser(TokenErrorCodes.ProblemWithResetPasswordToken),
    },
    [TokenErrorCodes.RefreshTokenExpired]: {
      title: i18NParser(TokenErrorCodes.RefreshTokenExpired),
    },
    [TokenErrorCodes.ResetPasswordTokenExpired]: {
      title: i18NParser(TokenErrorCodes.ResetPasswordTokenExpired),
    },
    [LeadErrorCodes.LeadAccessDenied]: {
      title: i18NParser(LeadErrorCodes.LeadAccessDenied),
    },
    [LeadErrorCodes.LeadNotFound]: {
      title: i18NParser(LeadErrorCodes.LeadNotFound),
    },
    [FolderErrorCodes.FolderAccessDenied]: {
      title: i18NParser(FolderErrorCodes.FolderAccessDenied),
    },
    [FolderErrorCodes.FolderNotFound]: {
      title: i18NParser(FolderErrorCodes.FolderNotFound),
    },
    [UploadedLeadErrorCodes.IncorrectFileStructure]: {
      title: i18NParser(UploadedLeadErrorCodes.IncorrectFileStructure),
    },
    [UploadedLeadErrorCodes.LeadIsNotUnique]: {
      title: i18NParser(UploadedLeadErrorCodes.LeadIsNotUnique),
    },
    [UploadedLeadErrorCodes.LeadScanCodeIsNotUnique]: {
      title: i18NParser(UploadedLeadErrorCodes.LeadScanCodeIsNotUnique),
    },
    [UploadedLeadErrorCodes.InvalidLeadType]: {
      title: i18NParser(UploadedLeadErrorCodes.InvalidLeadType),
    },
    [UploadedLeadErrorCodes.InvalidNumericType]: {
      title: i18NParser(UploadedLeadErrorCodes.InvalidNumericType),
    },
    [UploadedLeadErrorCodes.InvalidDateFormat]: {
      title: i18NParser(UploadedLeadErrorCodes.InvalidDateFormat),
    },
    [UploadedLeadErrorCodes.InvalidStateAbbreviation]: {
      title: i18NParser(UploadedLeadErrorCodes.InvalidStateAbbreviation),
    },
    [UploadedLeadErrorCodes.InvalidCounty]: {
      title: i18NParser(UploadedLeadErrorCodes.InvalidCounty),
    },
    [UploadedLeadErrorCodes.MissingRequiredField]: {
      title: i18NParser(UploadedLeadErrorCodes.MissingRequiredField),
    },
    [UploadedLeadErrorCodes.RequestLeadIdsRequired]: {
      title: i18NParser(UploadedLeadErrorCodes.RequestLeadIdsRequired),
    },
    max_size: {
      title: 'Your file is too big!',
      message: 'Max file size is {{size}} MB',
    },
    //TODO: uncommit when LeadErrorCodes.InvalidLeadType will be added
    // [LeadErrorCodes.InvalidLeadType]: {
    //   title: i18NParser(LeadErrorCodes.InvalidLeadType),
    // },
  },
  success: {
    account_create: {
      title: 'Account Created!',
      message: 'Your account has been successfully created.',
    },
    account_edited: {
      title: 'Account Edited!',
      message:
        'Your account details have been updated.\nYour changes have been saved.',
    },
    account_activated: {
      title: 'Account Activated!',
      message: 'Account has been successfully activated.',
    },
    account_deactivated: {
      title: 'Account Deactivated!',
      message: 'Account has been successfully disabled.',
    },
    resend_fp_sent: {
      title: 'Success!',
      message: 'Reset password link successfully sended',
    },
    invite_resended: {
      title: 'Invite Resent!',
      message:
        'Your invitation has been successfully resent.\nThe recipient will receive a new invitation shortly',
    },
    reassign_leads: {
      title: 'Success!',
      message: 'Operation successful',
    },
    update_configuration: {
      title: 'Success!',
      message: 'Pdf configuration was updated',
    },
    settings_updated: {
      title: 'Success!',
      message: 'Settings was updated',
    },
    template_updated: {
      title: 'Success!',
      message: 'Template was updated',
    },
    folder_created: {
      title: 'Success!',
      message: 'Folder successfully created',
    },
    folder_updated: {
      title: 'Success!',
      message: 'Folder successfully updated',
    },
    folder_removed: {
      title: 'Success!',
      message: 'Folder successfully removed',
    },
  },
  warning: {},
  info: {
    deactivate_account: {
      title: 'Deactivate account?',
      message:
        'Please note that this action is permanent and cannot be reversed.',
    },
    delete_integration: {
      title: 'Delete integration?',
      message: 'Are you sure you want to delete this integration?',
    },
  },
  field_validation: {
    valid: 'Please enter a valid {{field}}',
    max_character: '{{field}} length <= {{range}} characters',
    required: '{{field}} field is required',
    incorrect: 'Please enter a correct {{field}}',
  },
};
