import { SerializedError } from '@reduxjs/toolkit';

import { IErrorDTO } from '../../common/interfaces/dto/common/ierror.interface';
import { IUploadedLeadResponseDTO } from '../../common/interfaces/dto/lead/iuploaded-lead-response.interface';
import { IGetUploadedLeadsResponseDTO } from '../../common/interfaces/dto/lead/iget-uploaded-leads-response.interface';

import { ILeadDTO } from '../../common/interfaces/dto/lead/ilead.interface';
import { IShortAccountDTO } from '../../common/interfaces/dto/account/ishort-account.interface';
import { IGetLeadsResponseDTO } from '../../common/interfaces/dto/lead/iget-leads-response.interface';
import { LeadType } from '../../common/enums/lead/lead-type.enum';

export interface ILeadsInitialState extends IGetLeadsResponseDTO {
  isFetching: boolean;
  error: IErrorDTO | null;
}

export interface IUploaLeadsResultInitialState
  extends Partial<IUploadedLeadResponseDTO> {
  isLoading: boolean;
  error: IErrorDTO | null;
}

export interface IGetTokenInitialState {
  isLoading: boolean;
  token: string;
  error: IErrorDTO | null;
}

export interface IUpdateLeadsInitialState {
  isLoading: boolean;
  isUpdated: boolean;
  error: IErrorDTO | null;
}

export interface IUploadingAttachmentInitialState {
  isLoading: boolean;
  isUploading: boolean;
  error: IErrorDTO | null;
}

export interface IBulkUploadingAttachmentInitialState {
  isLoading: boolean;
  isBulkUploading: boolean;
  isResultReceived: boolean;
  link: string;
  uid?: string;
  errors: number;
  success: number;
  total: number;
  error: IErrorDTO | null;
}

export interface IGetLeadDetailsInitialState {
  isLoading: boolean;
  lead: ILeadDTO | null;
  error: IErrorDTO | null;
}

export interface IUploadedLeadsInitialState
  extends IGetUploadedLeadsResponseDTO {
  isFetching: boolean;
  isRemoved: boolean;
  isAploadedLeadsApplied: boolean;
  shouldResetSelection: boolean;
  isSubmitTriggered: boolean;
  error: IErrorDTO | null;
}

export interface ILeadChilds {
  mainAccounts: IShortAccountDTO[];
  subAccounts: IShortAccountDTO[];
  agentAccounts: IShortAccountDTO[];
  isFetching: boolean;
  error: IErrorDTO | null;
}

export interface IGetTokenForExportLeadsAsCsv {
  token: string;
  isFetching: boolean;
  error: IErrorDTO | null;
}

export interface IGetDownloadAttachmentLink {
  link: string;
  isFetching: boolean;
  error: IErrorDTO | null;
}

export type TLeadsReducerInitialState = {
  getLeads: ILeadsInitialState;
  getFolderLeads: ILeadsInitialState;
  uploadLeadsResult: IUploaLeadsResultInitialState;
  getVerificationToken: IGetTokenInitialState;
  getCsvErrorsToken: IGetTokenInitialState;
  updateLeads: IUpdateLeadsInitialState;
  uploadAttachmentResult: IUploadingAttachmentInitialState;
  bulkUploadAttachmentResult: IBulkUploadingAttachmentInitialState;
  getLeadDetails: IGetLeadDetailsInitialState;
  getUploadedLeads: IUploadedLeadsInitialState;
  getLeadChilds: ILeadChilds;
  unexpectedError: SerializedError | null;
  getTokenForExportLeadsAsCsv: IGetTokenForExportLeadsAsCsv;
  getDownloadAttachmentLink: IGetDownloadAttachmentLink;
  getDownloadBulkUploadErrorsLink: IGetDownloadAttachmentLink;
};

export enum EChildAccountType {
  SUB = 'sub',
  MAIN = 'main',
  AGENT = 'agent',
}

export interface IFilterLeadData {
  leadType?: LeadType[];
  mainAccountId?: number;
  subAccountId?: number;
  affiliates?: number;
  agentId?: number;
  stateId?: number;
  countyIds?: number[];
  dateFrom?: string;
  dateTo?: string;
  hasAttachments?: string;
}
