export enum AuthRoutes {
  BasePrefix = "auth",
  Login = "login",
  ChangePassword = "change-password",
  PasswordRecovery = "recovery",
  ResetPassword = "password",
  RefreshToken = "token/refresh",
  TokenValidPassword = "token/valid/password",
  TokenValidInvite = "token/valid/invite"
}
