import { httpFolders } from '../../api';
import { LeadType } from '../../common/enums/lead/lead-type.enum';
import { FolderRoutes } from '../../common/enums/routes/folder-routes.enum';
import { ICreateFolderRequestDTO } from '../../common/interfaces/dto/folder/icreate-folder-request.interface';
import { IExtendedFolderResponseDTO } from '../../common/interfaces/dto/folder/iextended-folder-response.interface';
import { IFolderResponseDTO } from '../../common/interfaces/dto/folder/ifolder-response.interface';
import { IUpdateFolderRequestDTO } from '../../common/interfaces/dto/folder/iupdate-folder-request.interface';
import { thunkCreator } from '../../utils/ThunkCreator';

export const getActiveFoldersThunk = thunkCreator<
  { folders: IExtendedFolderResponseDTO[] },
  { isDashboardOnly: boolean; leadType?: LeadType }
>('folders/getActiveFoldersThunk', httpFolders, '', 'GET');

export const getFoldersThunk = thunkCreator<
  { folders: IExtendedFolderResponseDTO[] },
  { searchQuery?: string }
>('folders/getFoldersThunk', httpFolders, '', 'GET');

export const getFolderByIdThunk = thunkCreator<
  IFolderResponseDTO,
  { folderId: number }
>('folders/getFolderThunk', httpFolders, FolderRoutes.ById, 'GET');

export const createFolderThunk = thunkCreator<
  IFolderResponseDTO,
  ICreateFolderRequestDTO
>('folders/createFolderThunk', httpFolders, '', 'POST');

export const updateFolderThunk = thunkCreator<
  IFolderResponseDTO,
  IUpdateFolderRequestDTO & { folderId: number; showDashboard?: boolean }
>('folders/updateFolderThunk', httpFolders, FolderRoutes.ById, 'PUT');

export const deleteFolderThunk = thunkCreator<
  boolean,
  { folderId: number; backupFolderId?: number }
>('folders/deleteFolderThunk', httpFolders, FolderRoutes.ById, 'DELETE');

export const moveFolderThunk = thunkCreator<
  IFolderResponseDTO,
  IUpdateFolderRequestDTO & { folderId: number; showDashboard?: boolean }
>('folders/updateFolderThunk', httpFolders, FolderRoutes.ById, 'PUT');
