import { SerializedError } from '@reduxjs/toolkit';
import { IAccountDTO } from '../../common/interfaces/dto/account/iaccount.interface';
import { ILoginRequestDTO } from '../../common/interfaces/dto/auth/ilogin-request.interface';
import { IErrorDTO } from '../../common/interfaces/dto/common/ierror.interface';

interface ICompleteRegistration {
  error: IErrorDTO | null;
  isLoading: boolean;
  isCompleted: boolean;
}

export enum ELinkType {
  INVITE = 'invite',
  RESET = 'reset',
}

interface ICheckIsLinkExpired {
  error: IErrorDTO | null;
  linkType: '' | ELinkType;
}

export interface IInitialState {
  isUserLogged: boolean;
  isUserLoggedFromLoginPage: boolean;
  account: IAccountDTO;
  isPending: boolean;
  error: IErrorDTO | null;
  unexpectedError: SerializedError | null;
  isEmailSend: boolean;
  isPasswordChange: boolean;
  isProfileInfoChange: boolean;
  tokenIsRefreshing: boolean;
  tokenRefreshFailed: boolean;
  completeRegistration: ICompleteRegistration;
  checkIsLinkExpired: ICheckIsLinkExpired;
}

export interface ILoginRequestData extends ILoginRequestDTO {
  remember: boolean;
}
