import React, { useEffect } from 'react';
import { useLocation } from 'react-router';

import Header from '../Header';
import InfoBox from '../InfoBox';
import { AutorizedWrapProps } from './interfaces';
import { setStorage } from '../../utils/StorageUtil';
import { EMessageType } from '../../service/enums';
import { resetFoldersError } from '../../redux/reducers/foldersReducer';
import { useAppSelector } from '../../hooks/hooks';
import { useError } from '../../hooks/useError';

const AuthorizedWrapper: React.FunctionComponent<AutorizedWrapProps> = ({
  children,
}) => {
  const { pathname } = useLocation();
  const {
    getAllFolders: { error: getFoldersError },
  } = useAppSelector(({ folders }) => folders);
  const { errorFromHook, onRessetError } = useError(
    resetFoldersError,
    getFoldersError,
  );

  useEffect(() => {
    setStorage('route', pathname);
  }, [pathname]);

  return (
    <main className="main">
      <Header />
      <section className="layout_section">
        <div className="container">{children}</div>
        {errorFromHook && (
          <InfoBox
            type={EMessageType.ERROR}
            textKey={errorFromHook.errorCode}
            params={errorFromHook.filedsValidationErrors?.[0] || {}}
            onClose={onRessetError}
          />
        )}
      </section>
    </main>
  );
};

export default AuthorizedWrapper;
