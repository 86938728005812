import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import {
  EChildAccountType,
  TLeadsReducerInitialState,
} from '../types/leadsTypes';
import {
  getLeadsListThunk,
  getVerificationToken,
  updateLeadsThunk,
  getLeadDetails,
  getUploadedLeadsThunk,
  removeUploadedLeadsThunk,
  applyUploadedLeadsThunk,
  getLeadChildAccounts,
  recieveTokenForExportLeadsAsCsvThunk,
  getLeadAttacmentLinkThunk,
  getDownloadBulkUploadErrorsLinkThunk,
  getUploadAttachmentsLinkThunk,
  getFolderLeadsListThunk,
  getBulkUploadResultThunk,
  getCsvErrorsToken,
} from '../thunks/leadsThunk';
import {
  bulkUploadAttachment,
  uploadAttachment,
  uploadFileWithLeads,
} from '../thunks/uploadDataThunk';
import { AccountType } from '../../common/enums/account/account-type.enum';

export const initialState: TLeadsReducerInitialState = {
  getLeads: {
    count: -1,
    pages: 0,
    page: 0,
    pageLimit: 0,
    leads: [],
    isFetching: false,
    error: null,
  },
  getFolderLeads: {
    count: -1,
    pages: 0,
    page: 0,
    pageLimit: 0,
    leads: [],
    isFetching: false,
    error: null,
  },
  uploadLeadsResult: {
    isLoading: false,
    uploadId: '',
    fileName: '',
    errors: 0,
    success: 0,
    total: 0,
    error: null,
  },
  uploadAttachmentResult: {
    isLoading: false,
    isUploading: false,
    error: null,
  },
  bulkUploadAttachmentResult: {
    isLoading: false,
    isBulkUploading: false,
    isResultReceived: false,
    link: '',
    uid: ' ',
    errors: 0,
    success: 0,
    total: 0,
    error: null,
  },
  getVerificationToken: {
    isLoading: false,
    token: '',
    error: null,
  },
  getCsvErrorsToken: {
    isLoading: false,
    token: '',
    error: null,
  },
  updateLeads: {
    isLoading: false,
    isUpdated: false,
    error: null,
  },
  getLeadDetails: {
    isLoading: false,
    lead: null,
    error: null,
  },
  getUploadedLeads: {
    count: -1,
    pages: 0,
    page: 0,
    pageLimit: 0,
    leads: [],
    isFetching: false,
    isRemoved: false,
    isAploadedLeadsApplied: false,
    shouldResetSelection: false,
    isSubmitTriggered: false,
    error: null,
  },
  getLeadChilds: {
    mainAccounts: [],
    subAccounts: [],
    agentAccounts: [],
    isFetching: false,
    error: null,
  },
  getTokenForExportLeadsAsCsv: {
    token: '',
    isFetching: false,
    error: null,
  },
  getDownloadAttachmentLink: {
    error: null,
    isFetching: false,
    link: '',
  },
  getDownloadBulkUploadErrorsLink: {
    error: null,
    isFetching: false,
    link: '',
  },

  unexpectedError: null,
};

const LeadsReducer = createSlice({
  name: 'Accounts',
  initialState,
  reducers: {
    resetGetLeadsState(state) {
      state.getLeads = initialState.getLeads;
      state.getFolderLeads = initialState.getFolderLeads;
    },
    resetAttachmentUpload(state) {
      state.uploadAttachmentResult.isUploading = false;
      state.bulkUploadAttachmentResult.isBulkUploading = false;
      state.bulkUploadAttachmentResult.isResultReceived = false;
    },
    refreshLeadsModalData(state) {
      state.updateLeads = initialState.updateLeads;
    },
    resetLeadsError(state) {
      state.getLeads.error = null;
      state.getFolderLeads.error = null;
      state.uploadLeadsResult.error = null;
      state.getVerificationToken.error = null;
      state.getCsvErrorsToken.error = null;
      state.updateLeads.error = null;
      state.bulkUploadAttachmentResult.error = null;
      state.uploadAttachmentResult.error = null;
      state.getLeadDetails.error = null;
      state.getUploadedLeads.error = null;
      state.getLeadChilds.error = null;
      state.getTokenForExportLeadsAsCsv.error = null;
      state.getDownloadAttachmentLink.error = null;
      state.getDownloadBulkUploadErrorsLink.error = null;
    },
    resetExportLeads(state) {
      state.getTokenForExportLeadsAsCsv =
        initialState.getTokenForExportLeadsAsCsv;
    },
    resetImportLeads(state) {
      state.uploadLeadsResult = initialState.uploadLeadsResult;
      state.getUploadedLeads = initialState.getUploadedLeads;
    },
    resetGetLeadDetails(state) {
      state.getLeadDetails = initialState.getLeadDetails;
    },
    resetVerificationToken(state) {
      state.getVerificationToken = initialState.getVerificationToken;
    },
    resetCsvErrorsToken(state) {
      state.getCsvErrorsToken = initialState.getCsvErrorsToken;
    },
    resetChildAccountsOnChange(state, action: PayloadAction<string>) {
      if (action.payload === EChildAccountType.MAIN) {
        state.getLeadChilds.agentAccounts =
          initialState.getLeadChilds.agentAccounts;
      }
    },
    resetChildAccounts(state, action: PayloadAction<string>) {
      if (action.payload === AccountType.Admin) {
        state.getLeadChilds.subAccounts =
          initialState.getLeadChilds.subAccounts;
      }

      if (
        action.payload === AccountType.Admin ||
        action.payload === AccountType.MainAccount
      ) {
        state.getLeadChilds.agentAccounts =
          initialState.getLeadChilds.agentAccounts;
      }
    },
    resetChildAccountsFully(state) {
      state.getLeadChilds = initialState.getLeadChilds;
    },
    resetGetAttacmentLink(state) {
      state.getDownloadAttachmentLink = initialState.getDownloadAttachmentLink;
    },
    resetBulkUploadErrorsLink(state) {
      state.getDownloadBulkUploadErrorsLink =
        initialState.getDownloadBulkUploadErrorsLink;
    },
    setShouldResetSelection(
      { getUploadedLeads },
      action: PayloadAction<boolean>,
    ) {
      getUploadedLeads.shouldResetSelection = action.payload;
    },
    setIsSubmitTriggered({ getUploadedLeads }, action: PayloadAction<boolean>) {
      getUploadedLeads.isSubmitTriggered = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getLeadsListThunk.pending, ({ getLeads }) => {
        getLeads.isFetching = true;
      })
      .addCase(getLeadsListThunk.fulfilled, ({ getLeads }, action) => {
        if (action.payload) {
          getLeads.leads = action.payload.leads;
          getLeads.count = action.payload.count;
          getLeads.pages = action.payload.pages;
          getLeads.page = action.payload.page;
        }
        getLeads.isFetching = false;
      })
      .addCase(getLeadsListThunk.rejected, (state, action) => {
        if (state.getLeads.isFetching) {
          state.getLeads.isFetching = false;
        }
        if (action.payload) {
          // Since we passed in `MyKnownError` to `rejectValue` in `updateAccount`, the type information will be available here.
          state.getLeads.error = action.payload;
        } else {
          state.unexpectedError = action.error;
        }
      })
      .addCase(getFolderLeadsListThunk.pending, ({ getFolderLeads }) => {
        getFolderLeads.isFetching = true;
      })
      .addCase(
        getFolderLeadsListThunk.fulfilled,
        ({ getFolderLeads }, action) => {
          if (action.payload) {
            getFolderLeads.leads = action.payload.leads;
            getFolderLeads.count = action.payload.count;
            getFolderLeads.pages = action.payload.pages;
            getFolderLeads.page = action.payload.page;
          }
          getFolderLeads.isFetching = false;
        },
      )
      .addCase(getFolderLeadsListThunk.rejected, (state, action) => {
        if (state.getFolderLeads.isFetching) {
          state.getFolderLeads.isFetching = false;
        }
        if (action.payload) {
          // Since we passed in `MyKnownError` to `rejectValue` in `updateAccount`, the type information will be available here.
          state.getFolderLeads.error = action.payload;
        } else {
          state.unexpectedError = action.error;
        }
      })
      .addCase(
        getUploadAttachmentsLinkThunk.pending,
        ({ bulkUploadAttachmentResult }) => {
          bulkUploadAttachmentResult.isLoading = true;
          bulkUploadAttachmentResult.isBulkUploading = false;
        },
      )
      .addCase(
        getUploadAttachmentsLinkThunk.fulfilled,
        ({ bulkUploadAttachmentResult }, action) => {
          if (action.payload) {
            bulkUploadAttachmentResult.link = action.payload.link;
            bulkUploadAttachmentResult.uid = action.payload.bulkUploadId;
          }
        },
      )
      .addCase(getUploadAttachmentsLinkThunk.rejected, (state, action) => {
        if (state.bulkUploadAttachmentResult.isLoading) {
          state.bulkUploadAttachmentResult.isLoading = false;
        }
        if (action.payload) {
          // Since we passed in `MyKnownError` to `rejectValue` in `updateAccount`, the type information will be available here.
          state.bulkUploadAttachmentResult.error = action.payload;
        } else {
          state.unexpectedError = action.error;
        }
      })
      .addCase(
        getBulkUploadResultThunk.pending,
        ({ bulkUploadAttachmentResult }) => {
          bulkUploadAttachmentResult.isLoading = true;
          bulkUploadAttachmentResult.isResultReceived = false;
        },
      )
      .addCase(
        getBulkUploadResultThunk.fulfilled,
        ({ bulkUploadAttachmentResult }, action) => {
          if (action.payload) {
            bulkUploadAttachmentResult.isResultReceived = true;
            bulkUploadAttachmentResult.errors = action.payload.errors;
            bulkUploadAttachmentResult.success = action.payload.success;
            bulkUploadAttachmentResult.total = action.payload.total;
          }
          bulkUploadAttachmentResult.isLoading = false;
        },
      )
      .addCase(getBulkUploadResultThunk.rejected, (state, action) => {
        if (state.bulkUploadAttachmentResult.isLoading) {
          state.bulkUploadAttachmentResult.isLoading = false;
        }
        if (action.payload) {
          // Since we passed in `MyKnownError` to `rejectValue` in `updateAccount`, the type information will be available here.
          state.bulkUploadAttachmentResult.error = action.payload;
        } else {
          state.unexpectedError = action.error;
        }
      })
      .addCase(uploadFileWithLeads.pending, ({ uploadLeadsResult }) => {
        uploadLeadsResult.isLoading = true;
        uploadLeadsResult.uploadId = '';
      })
      .addCase(uploadFileWithLeads.fulfilled, (state, action) => {
        if (action.payload) {
          state.uploadLeadsResult = {
            isLoading: false,
            error: null,
            ...action.payload,
          };
        }
      })
      .addCase(uploadFileWithLeads.rejected, (state, action) => {
        if (state.uploadLeadsResult.isLoading) {
          state.uploadLeadsResult.isLoading = false;
        }
        if (action.payload) {
          // Since we passed in `MyKnownError` to `rejectValue` in `updateAccount`, the type information will be available here.
          state.uploadLeadsResult.error = action.payload;
        } else {
          state.unexpectedError = action.error;
        }
      })
      .addCase(
        bulkUploadAttachment.pending,
        ({ bulkUploadAttachmentResult }) => {
          bulkUploadAttachmentResult.isLoading = true;
          bulkUploadAttachmentResult.isBulkUploading = false;
        },
      )
      .addCase(bulkUploadAttachment.fulfilled, (state, action) => {
        if (action.payload) {
          console.log(action.payload);
          state.bulkUploadAttachmentResult.isBulkUploading = action.payload;
        }
      })
      .addCase(bulkUploadAttachment.rejected, (state, action) => {
        if (state.bulkUploadAttachmentResult.isLoading) {
          state.bulkUploadAttachmentResult.isLoading = false;
        }
        if (action.payload) {
          // Since we passed in `MyKnownError` to `rejectValue` in `updateAccount`, the type information will be available here.
          state.bulkUploadAttachmentResult.error = action.payload;
        } else {
          state.unexpectedError = action.error;
        }
      })
      .addCase(uploadAttachment.pending, ({ uploadAttachmentResult }) => {
        uploadAttachmentResult.isLoading = true;
        uploadAttachmentResult.isUploading = false;
      })
      .addCase(uploadAttachment.fulfilled, (state, action) => {
        if (action.payload) {
          state.uploadAttachmentResult = {
            isLoading: false,
            error: null,
            isUploading: true,
          };
        }
      })
      .addCase(uploadAttachment.rejected, (state, action) => {
        if (state.uploadAttachmentResult.isLoading) {
          state.uploadAttachmentResult.isLoading = false;
        }
        if (action.payload) {
          // Since we passed in `MyKnownError` to `rejectValue` in `updateAccount`, the type information will be available here.
          state.uploadAttachmentResult.error = action.payload;
        } else {
          state.unexpectedError = action.error;
        }
      })
      .addCase(getVerificationToken.pending, ({ getVerificationToken }) => {
        getVerificationToken.isLoading = true;
      })
      .addCase(
        getVerificationToken.fulfilled,
        ({ getVerificationToken }, action) => {
          getVerificationToken.isLoading = false;
          getVerificationToken.token = action.payload?.token || '';
        },
      )
      .addCase(getVerificationToken.rejected, (state, action) => {
        if (state.getVerificationToken.isLoading) {
          state.getVerificationToken.isLoading = false;
        }
        if (action.payload) {
          state.getVerificationToken.error = action.payload;
        } else {
          state.unexpectedError = action.error;
        }
      })
      .addCase(getCsvErrorsToken.pending, ({ getCsvErrorsToken }) => {
        getCsvErrorsToken.isLoading = true;
      })
      .addCase(getCsvErrorsToken.fulfilled, ({ getCsvErrorsToken }, action) => {
        getCsvErrorsToken.isLoading = false;
        getCsvErrorsToken.token = action.payload?.token || '';
      })
      .addCase(getCsvErrorsToken.rejected, (state, action) => {
        if (state.getCsvErrorsToken.isLoading) {
          state.getCsvErrorsToken.isLoading = false;
        }
        if (action.payload) {
          state.getCsvErrorsToken.error = action.payload;
        } else {
          state.unexpectedError = action.error;
        }
      })
      .addCase(updateLeadsThunk.pending, ({ updateLeads }) => {
        updateLeads.isLoading = true;
      })
      .addCase(updateLeadsThunk.fulfilled, ({ updateLeads }, action) => {
        updateLeads.isLoading = false;
        if (action.payload) {
          updateLeads.isUpdated = action.payload;
        }
      })
      .addCase(updateLeadsThunk.rejected, (state, action) => {
        if (state.updateLeads.isLoading) {
          state.updateLeads.isLoading = false;
        }
        if (action.payload) {
          state.updateLeads.error = action.payload;
        } else {
          state.unexpectedError = action.error;
        }
      })
      .addCase(getLeadDetails.pending, ({ getLeadDetails }) => {
        getLeadDetails.isLoading = true;
      })
      .addCase(getLeadDetails.fulfilled, ({ getLeadDetails }, action) => {
        getLeadDetails.isLoading = false;
        getLeadDetails.lead = action.payload;
      })
      .addCase(getLeadDetails.rejected, (state, action) => {
        if (state.getLeadDetails.isLoading) {
          state.getLeadDetails.isLoading = false;
        }
        if (action.payload) {
          state.getLeadDetails.error = action.payload;
        } else {
          state.unexpectedError = action.error;
        }
      })
      .addCase(getUploadedLeadsThunk.pending, ({ getUploadedLeads }) => {
        getUploadedLeads.isFetching = true;
      })
      .addCase(
        getUploadedLeadsThunk.fulfilled,
        ({ getUploadedLeads }, action) => {
          getUploadedLeads.isFetching = false;
          if (action.payload) {
            getUploadedLeads.leads = action.payload.leads;
            getUploadedLeads.count = action.payload.count;
            getUploadedLeads.pages = action.payload.pages;
            getUploadedLeads.page = action.payload.page;
          }
          getUploadedLeads.isAploadedLeadsApplied = false;
          getUploadedLeads.shouldResetSelection = true;
        },
      )
      .addCase(getUploadedLeadsThunk.rejected, (state, action) => {
        if (state.getUploadedLeads.isFetching) {
          state.getUploadedLeads.isFetching = false;
        }
        if (action.payload) {
          state.getUploadedLeads.error = action.payload;
        } else {
          state.unexpectedError = action.error;
        }
      })
      .addCase(removeUploadedLeadsThunk.pending, ({ getUploadedLeads }) => {
        getUploadedLeads.isFetching = true;
      })
      .addCase(
        removeUploadedLeadsThunk.fulfilled,
        ({ getUploadedLeads, uploadLeadsResult }) => {
          getUploadedLeads.isFetching = false;
          getUploadedLeads.isRemoved = false;
          uploadLeadsResult.uploadId = '';
          getUploadedLeads.leads = [];
          getUploadedLeads.count = 0;
        },
      )
      .addCase(removeUploadedLeadsThunk.rejected, (state, action) => {
        if (state.getUploadedLeads.isFetching) {
          state.getUploadedLeads.isFetching = false;
        }
        if (action.payload) {
          state.getUploadedLeads.error = action.payload;
        } else {
          state.unexpectedError = action.error;
        }
      })
      .addCase(applyUploadedLeadsThunk.pending, ({ getUploadedLeads }) => {
        getUploadedLeads.isFetching = true;
      })
      .addCase(applyUploadedLeadsThunk.fulfilled, ({ getUploadedLeads }) => {
        getUploadedLeads.isFetching = false;
        getUploadedLeads.isAploadedLeadsApplied = true;

        //TODO: uncommit this when errors, success, total will be added on backend
        // uploadLeadsResult.errors = action.payload.errors;
        // uploadLeadsResult.success = action.payload.success;
        // uploadLeadsResult.total = action.payload.total;
      })
      .addCase(applyUploadedLeadsThunk.rejected, (state, action) => {
        if (state.getUploadedLeads.isFetching) {
          state.getUploadedLeads.isFetching = false;
        }
        if (action.payload) {
          state.getUploadedLeads.error = action.payload;
        } else {
          state.unexpectedError = action.error;
        }
      })
      .addCase(getLeadChildAccounts.pending, ({ getLeadChilds }) => {
        getLeadChilds.isFetching = true;
      })
      .addCase(getLeadChildAccounts.fulfilled, ({ getLeadChilds }, action) => {
        getLeadChilds.isFetching = false;

        if (action.payload) {
          switch (action.meta.arg.metaData) {
            case AccountType.Agent:
              getLeadChilds.agentAccounts = action.payload.accounts;
              break;

            case AccountType.SubAccount:
              getLeadChilds.subAccounts = action.payload.accounts;
              break;

            default:
              getLeadChilds.mainAccounts = action.payload.accounts;
          }
        }
      })
      .addCase(getLeadChildAccounts.rejected, (state, action) => {
        if (state.getLeadChilds.isFetching) {
          state.getLeadChilds.isFetching = false;
        }
        if (action.payload) {
          state.getLeadChilds.error = action.payload;
        } else {
          state.unexpectedError = action.error;
        }
      })
      .addCase(
        recieveTokenForExportLeadsAsCsvThunk.pending,
        ({ getTokenForExportLeadsAsCsv }) => {
          getTokenForExportLeadsAsCsv.isFetching = true;
        },
      )
      .addCase(
        recieveTokenForExportLeadsAsCsvThunk.fulfilled,
        ({ getTokenForExportLeadsAsCsv }, action) => {
          getTokenForExportLeadsAsCsv.isFetching = false;

          if (action.payload?.token) {
            getTokenForExportLeadsAsCsv.token = action.payload.token;
          }
        },
      )
      .addCase(
        recieveTokenForExportLeadsAsCsvThunk.rejected,
        (state, action) => {
          if (state.getTokenForExportLeadsAsCsv.isFetching) {
            state.getTokenForExportLeadsAsCsv.isFetching = false;
          }
          if (action.payload) {
            state.getTokenForExportLeadsAsCsv.error = action.payload;
          } else {
            state.unexpectedError = action.error;
          }
        },
      )
      .addCase(
        getLeadAttacmentLinkThunk.pending,
        ({ getDownloadAttachmentLink }) => {
          getDownloadAttachmentLink.isFetching = true;
        },
      )
      .addCase(
        getLeadAttacmentLinkThunk.fulfilled,
        ({ getDownloadAttachmentLink }, action) => {
          getDownloadAttachmentLink.isFetching = false;
          if (action.payload) {
            getDownloadAttachmentLink.link = action.payload.link;
          }
        },
      )
      .addCase(getLeadAttacmentLinkThunk.rejected, (state, action) => {
        if (state.getDownloadAttachmentLink.isFetching) {
          state.getDownloadAttachmentLink.isFetching = false;
        }
        if (action.payload) {
          state.getDownloadAttachmentLink.error = action.payload;
        } else {
          state.unexpectedError = action.error;
        }
      })
      .addCase(
        getDownloadBulkUploadErrorsLinkThunk.pending,
        ({ getDownloadBulkUploadErrorsLink }) => {
          getDownloadBulkUploadErrorsLink.isFetching = true;
        },
      )
      .addCase(
        getDownloadBulkUploadErrorsLinkThunk.fulfilled,
        ({ getDownloadBulkUploadErrorsLink }, action) => {
          getDownloadBulkUploadErrorsLink.isFetching = false;
          if (action.payload) {
            getDownloadBulkUploadErrorsLink.link = action.payload.link;
          }
        },
      )
      .addCase(
        getDownloadBulkUploadErrorsLinkThunk.rejected,
        (state, action) => {
          if (state.getDownloadBulkUploadErrorsLink.isFetching) {
            state.getDownloadBulkUploadErrorsLink.isFetching = false;
          }
          if (action.payload) {
            state.getDownloadBulkUploadErrorsLink.error = action.payload;
          } else {
            state.unexpectedError = action.error;
          }
        },
      );
  },
});

export const {
  resetGetLeadsState,
  resetLeadsError,
  resetImportLeads,
  resetAttachmentUpload,
  resetGetLeadDetails,
  resetVerificationToken,
  resetCsvErrorsToken,
  resetChildAccountsOnChange,
  resetChildAccounts,
  resetChildAccountsFully,
  refreshLeadsModalData,
  setShouldResetSelection,
  setIsSubmitTriggered,
  resetExportLeads,
  resetGetAttacmentLink,
  resetBulkUploadErrorsLink,
} = LeadsReducer.actions;

export default LeadsReducer.reducer;
