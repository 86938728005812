import { createAsyncThunk } from '@reduxjs/toolkit';
import { IUploadedLeadResponseDTO } from '../../common/interfaces/dto/lead/iuploaded-lead-response.interface';
import { LeadRoutes } from '../../common/enums/routes/lead-routes.enum';
import { IShortAttachmentDTO } from '../../common/interfaces/dto/attachment/ishort-attachment.interface';
import { IErrorDTO } from '../../common/interfaces/dto/common/ierror.interface';

import { httpLeads } from '../../api';
import { uploadThunkCreator } from '../../utils/FileThunkCreator';

export const uploadFileWithLeads = uploadThunkCreator<IUploadedLeadResponseDTO>(
  'leadsReducer/uploadFileWithLeads',
  httpLeads,
  LeadRoutes.Uploads,
);

export const uploadAttachment = uploadThunkCreator<IShortAttachmentDTO>(
  'leadsReducer/uploadAttachment',
  httpLeads,
  LeadRoutes.UploadAttachments,
);

export const bulkUploadAttachment = createAsyncThunk<
  boolean,
  { file: File; link: string },
  { rejectValue: IErrorDTO | null }
>(
  'leadsReducer/bulkUploadAttachment',
  async ({ file, link }, { rejectWithValue }) => {
    const response = await fetch(link, {
      method: 'PUT',
      body: file,
    });
    if (response.status !== 200) {
      return rejectWithValue({
        errorCode: 'file_error',
      });
    }
    return true;
  },
);
