import { API_URL } from '../SetupConfig';
import { AccountRoutes } from '../common/enums/routes/account-routes.enum';
import { AssetRoutes } from '../common/enums/routes/asset-routes.enum';
import { AuthRoutes } from '../common/enums/routes/auth-routes.enum';
import { BaseRoutes } from '../common/enums/routes/base-routes.enum';
import { FolderRoutes } from '../common/enums/routes/folder-routes.enum';
import { LeadRoutes } from '../common/enums/routes/lead-routes.enum';
import { SettingRoutes } from '../common/enums/routes/setting-routes.enum';
import { StateRoutes } from '../common/enums/routes/state-routes.enum';

export const postLeadsURL = `${API_URL || ''}${BaseRoutes.V1}/${
  LeadRoutes.BasePrefix
}`;

export const getPath = (namespace: string): string => {
  return `${API_URL || ''}${BaseRoutes.V1}/${namespace}`;
};

export const getBearerAccessToken = (token?: string): string => {
  return token ? `Bearer ${token}` : '';
};

export const httpAuth = getPath(AuthRoutes.BasePrefix);
export const httpAccount = getPath(AccountRoutes.BasePrefix);
export const httpLeads = getPath(LeadRoutes.BasePrefix);
export const httpSettings = getPath(SettingRoutes.BasePrefix);
export const httpFolders = getPath(FolderRoutes.BasePrefix);
export const httpStates = getPath(StateRoutes.BasePrefix);
export const httpAssets = getPath(AssetRoutes.BasePrefix);
