import { configureStore } from '@reduxjs/toolkit';
import accountReducer from './reducers/accountReducer';
import authReducer from './reducers/authReducer';
import leadsReducer from './reducers/leadsReducer';
import settingsReducer from './reducers/settingsReducer';
import pdfTemplateReducer from './reducers/pdfTemplateReducer';
import foldersReducer from './reducers/foldersReducer';
import statesReducer from './reducers/statesReducer';
import dashboardReducer from './reducers/dashboardReducer';
import reportsReducer from './reducers/reportsReducer';

export const store = configureStore({
  reducer: {
    auth: authReducer,
    accounts: accountReducer,
    leads: leadsReducer,
    settings: settingsReducer,
    pdfTemplate: pdfTemplateReducer,
    folders: foldersReducer,
    states: statesReducer,
    dashboard: dashboardReducer,
    reports: reportsReducer,
  },
});
