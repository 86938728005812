import React from 'react';
import classNames from 'classnames';
import loading from '../../assets/img/loading.svg';
import { IButton } from './types';

const Button = ({
  onClick,
  children,
  className,
  isLoading = false,
  ...props
}: IButton): JSX.Element => {
  return (
    <button
      className={classNames('btn', className)}
      onClick={onClick}
      {...props}
      title=""
    >
      {isLoading && <img className="img-loading" src={loading} alt="loading" />}
      {children}
    </button>
  );
};

export default Button;
