import { httpStates } from '../../api';
import { StateRoutes } from '../../common/enums/routes/state-routes.enum';
import { IGetCountiesOfStateResponseDTO } from '../../common/interfaces/dto/state/iget-counties-of-state-response.interface';
import { IGetStatesResponseDTO } from '../../common/interfaces/dto/state/iget-states-response.interface';
import { thunkCreator } from '../../utils/ThunkCreator';

export const getStateListThunk = thunkCreator<
  IGetStatesResponseDTO,
  Record<string, undefined>
>('states/getStateList', httpStates, '', 'GET');

export const getCountiesListThunk = thunkCreator<
  IGetCountiesOfStateResponseDTO,
  { stateId: number }
>('states/getCountiesList', httpStates, StateRoutes.GetCountiesOfState, 'GET');
