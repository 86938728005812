import { AccountType } from '../common/enums/account/account-type.enum';
import { IPdfConfigurationDTO } from '../common/interfaces/dto/account/ipdf-configuration.interface';
import { IShortAccountDTO } from '../common/interfaces/dto/account/ishort-account.interface';
import { IExtendedFolderResponseDTO } from '../common/interfaces/dto/folder/iextended-folder-response.interface';
import { ICountyDTO } from '../common/interfaces/dto/state/icounty.interface';
import { IStateDTO } from '../common/interfaces/dto/state/istate.interface';

import { ISelect } from '../service/types';

export const initSelectOption: { value: ''; label: string } = {
  value: '',
  label: 'Select option',
};

export const accountTypes = (role: AccountType): ISelect<AccountType>[] => {
  if (role === AccountType.Agent) {
    return [];
  }

  const selectOptions: ISelect<AccountType>[] = [
    {
      value: AccountType.Agent,
      label: AccountType.Agent,
    },
  ];

  if (role === AccountType.Admin) {
    selectOptions.push({
      value: AccountType.Restricted,
      label: AccountType.Restricted,
    });
  }

  if (role === AccountType.SubAccount) {
    return selectOptions;
  }

  selectOptions.unshift({
    value: AccountType.SubAccount,
    label: AccountType.SubAccount,
  });

  if (role === AccountType.MainAccount) {
    return selectOptions;
  }

  selectOptions.unshift({
    value: AccountType.MainAccount,
    label: AccountType.MainAccount,
  });

  return selectOptions;
};

export const convertToSelectOptions = <T = string>(
  values:
    | IShortAccountDTO[]
    | IStateDTO[]
    | ICountyDTO[]
    | IExtendedFolderResponseDTO[]
    | IExtendedFolderResponseDTO[],
  initOption?: ISelect<T>,
): ISelect<T>[] => {
  const result: ISelect<T>[] = initOption ? [initOption] : [];

  values.forEach((item) =>
    result.push({ value: item.id as unknown as T, label: item.name }),
  );

  return result;
};

export const convertTemplatesToSelectOptions = (
  values: IPdfConfigurationDTO[],
): ISelect[] => {
  const result: ISelect[] = [initSelectOption];

  values.forEach((item) =>
    result.push({
      value: String(item.account.id),
      label: `${item.name} - ${item.account.name}`,
    }),
  );

  return result;
};

export const findOptionFromSelect = (
  options: ISelect[],
  initOption: ISelect,
  value?: string | number,
): ISelect => {
  if (value) {
    return (
      options.find((s) => String(s.value) === String(value)) || initSelectOption
    );
  }

  return initOption;
};
