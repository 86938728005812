import { createAsyncThunk } from '@reduxjs/toolkit';
import { AccountRoutes } from '../../common/enums/routes/account-routes.enum';
import { IAccountPdfTemplatesDTO } from '../../common/interfaces/dto/account/iaccount-pdf-templates.interface';
import { IGetPdfConfigurationsDTO } from '../../common/interfaces/dto/account/iget-pdf-configurations.interface';
import { IErrorDTO } from '../../common/interfaces/dto/common/ierror.interface';
import { LeadRoutes } from '../../common/enums/routes/lead-routes.enum';
import { IPdfTemplateDTO } from '../../common/interfaces/dto/account/ipdf-template.interface';

import { getAccessToken } from '../../utils/StorageUtil';
import { getBearerAccessToken, httpAccount, httpLeads } from '../../api';
import { thunkCreator } from '../../utils/ThunkCreator';

export const getConfigureTemplatesThunk = thunkCreator<
  IGetPdfConfigurationsDTO,
  Record<string, undefined>
>(
  'pdfTemplate/getConfigureTemplates',
  httpAccount,
  AccountRoutes.Templates,
  'GET',
);

export const getPdfTemplateThunk = thunkCreator<
  IAccountPdfTemplatesDTO,
  { accountId?: number }
>(
  'pdfTemplate/getPdfTemplate',
  httpAccount,
  `${AccountRoutes.ById}/${AccountRoutes.Templates}`,
  'GET',
);

export const applyTemplateToOtherAccountsThunk = thunkCreator<
  boolean,
  { accountId?: number; data: number[]; metaData: string }
>(
  'pdfTemplate/applyTemplateToOtherAccounts',
  httpAccount,
  `${AccountRoutes.ById}/${AccountRoutes.Templates}`,
  'POST',
);

export const updateTemplateThunk = thunkCreator<
  boolean,
  IAccountPdfTemplatesDTO & { accountId?: number }
>(
  'pdfTemplate/updateTemplate',
  httpAccount,
  `${AccountRoutes.ById}/${AccountRoutes.Templates}`,
  'PUT',
);

export const exportTemplateThunk = createAsyncThunk<
  Blob,
  Omit<IPdfTemplateDTO, 'leadType'>,
  { rejectValue: IErrorDTO | null }
>(
  'pdfTemplate/exportTemplate',
  async (data: Omit<IPdfTemplateDTO, 'leadType'>, { rejectWithValue }) => {
    const response = await fetch(
      `${httpLeads}/${LeadRoutes.ExportPdfPreview}`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: getBearerAccessToken(getAccessToken()),
        },
        body: JSON.stringify({
          ...data,
          accessToken: getAccessToken(),
        }),
      },
    );
    const contentType = response.headers.get('Content-Type');

    if (contentType === 'application/pdf') {
      const file = await response.blob();
      return file;
    }

    const errorResult: { error: IErrorDTO } = await response.json();
    return rejectWithValue(errorResult.error);
  },
);
