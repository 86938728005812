import { httpLeads } from '../../api';
import { LeadReportSortBy } from '../../common/enums/lead/lead-report-sort-by.enum';
import { LeadRoutes } from '../../common/enums/routes/lead-routes.enum';
import { IGetLeadReportsResponsesDTO } from '../../common/interfaces/dto/lead/iget-lead-reports-response.interface';
import { RequestBaseType } from '../../hooks/useSerializedParams';
import { thunkCreator } from '../../utils/ThunkCreator';
import { IFilterLeadData } from '../types/leadsTypes';

export const getLeadReportsThunk = thunkCreator<
  IGetLeadReportsResponsesDTO,
  RequestBaseType<LeadReportSortBy> & IFilterLeadData
>('leads/getLeadReportsThunk', httpLeads, LeadRoutes.Reports, 'GET');
