import { createSlice } from '@reduxjs/toolkit';

import { IReportsState } from '../types/reportsTypes';
import { getLeadReportsThunk } from '../thunks/reportsThunk';

export const initialState: IReportsState = {
  getReports: {
    count: -1,
    pages: 0,
    page: 0,
    pageLimit: 0,
    reports: [],
    sortBy: '',
    sortOrder: '',
    isFetching: false,
    error: null,
  },

  unexpectedError: null,
};

const ReportsReducer = createSlice({
  name: 'Reports',
  initialState,
  reducers: {
    resetReportsErrors({ getReports }) {
      getReports.error = null;
    },
    resetGetReports(state) {
      state.getReports = initialState.getReports;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getLeadReportsThunk.pending, ({ getReports }) => {
        getReports.reports = [];
        getReports.isFetching = true;
      })
      .addCase(getLeadReportsThunk.fulfilled, ({ getReports }, action) => {
        if (action.payload) {
          getReports.reports = action.payload.reports;
          getReports.count = action.payload.count;
          getReports.pages = action.payload.pages;
          getReports.page = action.payload.page;
        }
        getReports.isFetching = false;
      })
      .addCase(getLeadReportsThunk.rejected, (state, action) => {
        if (state.getReports.isFetching) {
          state.getReports.isFetching = false;
        }
        if (action.payload) {
          state.getReports.error = action.payload;
        } else {
          state.unexpectedError = action.error;
        }
      });
  },
});

export const { resetReportsErrors, resetGetReports } = ReportsReducer.actions;

export default ReportsReducer.reducer;
