import { AsyncThunk, createAsyncThunk } from '@reduxjs/toolkit';
import { refreshTokenThunk } from '../redux/thunks/authThunk';
import { getBearerAccessToken } from '../api';

import { IErrorDTO } from '../common/interfaces/dto/common/ierror.interface';
import { getAccessToken } from './StorageUtil';
import { IApiResponseGenericDTO } from '../common/interfaces/dto/common/iapi-response.interface';
import { signOut } from '../redux/reducers/authReducer';

export function uploadThunkCreator<TResponse>(
  typePrefix: string,
  basePrefix: string,
  link: string,
): AsyncThunk<
  TResponse,
  { file: File } & Record<string, string | File | undefined>,
  { rejectValue: IErrorDTO | null }
> {
  return createAsyncThunk<
    TResponse,
    { file: File } & Record<string, string | File | undefined>,
    { rejectValue: IErrorDTO | null }
  >(
    typePrefix,
    async (
      data: { file: File } & Record<string, string | File | undefined>,
      { rejectWithValue, dispatch, signal },
    ) => {
      let resultLink = link ? `/${link}` : '';

      if (link.includes(':')) {
        const indexStart = link.indexOf(':');
        let indexEnd = link.indexOf('/', indexStart);
        indexEnd = indexEnd > -1 ? indexEnd : link.length;
        const key = link.slice(indexStart + 1, indexEnd);
        resultLink = `/${link.slice(0, indexStart)}${data[key] || ''}${
          indexEnd > -1 ? link.slice(indexEnd) : ''
        }`;
        delete data[key];
      }

      const formData = new FormData();
      formData.append('file', data.file, data.file.name);

      const fetchMethod = async () =>
        fetch(`${basePrefix}${resultLink}`, {
          method: 'POST',
          headers: {
            // 'Content-Type': 'multipart/form-data',
            Authorization: getBearerAccessToken(getAccessToken()),
          },
          body: formData,
          signal,
        });

      let response = await fetchMethod();

      if (response.status === 401) {
        try {
          const refreshResult = await dispatch(refreshTokenThunk()).unwrap();
          if (refreshResult?.account.id) {
            response = await fetchMethod();
          }
        } catch (e) {
          console.error(e);
          return rejectWithValue(null);
        }
      }

      const jsonData = await response.json();

      const successResult = jsonData as TResponse;

      const errorResult = jsonData as IApiResponseGenericDTO<TResponse>;

      if (
        errorResult.error &&
        (errorResult.statusCode !== 200 || !errorResult.success)
      ) {
        if (errorResult.statusCode === 401) {
          dispatch(signOut());
        }

        return rejectWithValue(errorResult.error);
      }

      return successResult;
    },
  );
}
