export enum AccountRoutes {
  BasePrefix = "accounts",
  CompleteRegistration = "complete-registration",
  ById = ":accountId",
  ByShortId = ":accountShortId",
  ResendInvite = "invite/resend",
  AvailableParents = "parents",
  AvailableChildren = "children",
  Subordinates = "subordinates",
  GetAffiliates = "affiliates",
  GetAgents = "agents",
  Templates = "templates"
}
