import { createAsyncThunk } from '@reduxjs/toolkit';
import { AccountRoutes } from '../../common/enums/routes/account-routes.enum';
import { IAccountDTO } from '../../common/interfaces/dto/account/iaccount.interface';
import { LeadRoutes } from '../../common/enums/routes/lead-routes.enum';
import { IGetUploadedLeadsResponseDTO } from '../../common/interfaces/dto/lead/iget-uploaded-leads-response.interface';
import { IDownloadTokenDTO } from '../../common/interfaces/dto/lead/idownload-token.interface';
import { IAvailableChildrenAccountsDTO } from '../../common/interfaces/dto/account/iavailable-children-accounts.interface';
import { LeadSortBy } from '../../common/enums/lead/lead-sort-by.enum';
import { UploadedLeadSortBy } from '../../common/enums/lead/uploaded-lead-sort-by.enum';
import { ILeadDTO } from '../../common/interfaces/dto/lead/ilead.interface';
import { IGetLeadsResponseDTO } from '../../common/interfaces/dto/lead/iget-leads-response.interface';
import { IApplyUploadedLeadsRequestDTO } from '../../common/interfaces/dto/lead/iapply-uploaded-leads-request.interface';
import { AccountType } from '../../common/enums/account/account-type.enum';
import { IExportLeadsRequestDTO } from '../../common/interfaces/dto/lead/iexport-leads.interface';
import { IGetUploadAttachmentsLink } from '../../common/interfaces/dto/attachment/iget-upload-attachments-link.interface';
import { IAssignLeadDTO } from '../../common/interfaces/dto/lead/iassign-lead.interface';
import { ILinkDTO } from '../../common/interfaces/dto/amazon-s3-bucket/ilink.interface';
import { IBulkUploadDTO } from '../../common/interfaces/dto/amazon-s3-bucket/ibulk-upload.interface';
import { IApiResponseGenericDTO } from '../../common/interfaces/dto/common/iapi-response.interface';
import { BulkUploadStatus } from '../../common/enums/lead/bulk-upload-status.enum';
import { IErrorDTO } from '../../common/interfaces/dto/common/ierror.interface';

import { getBearerAccessToken, httpAccount, httpLeads } from '../../api';
import { RequestBaseType } from '../../hooks/useSerializedParams';

import { thunkCreator } from '../../utils/ThunkCreator';
import { getAccessToken } from '../../utils/StorageUtil';
import { waitMs } from '../../utils/GeneralUtils';

import { IFilterLeadData } from '../types/leadsTypes';
import { signOut } from '../reducers/authReducer';
import { refreshTokenThunk } from './authThunk';

export const getLeadsListThunk = thunkCreator<
  IGetLeadsResponseDTO,
  RequestBaseType<LeadSortBy> & IFilterLeadData & { onlyUncategorized: boolean }
>('leads/getLeadsList', httpLeads, '', 'GET');

export const getFolderLeadsListThunk = thunkCreator<
  IGetLeadsResponseDTO,
  RequestBaseType<LeadSortBy> & IFilterLeadData & { folderId: number | null }
>('leads/getFolderLeadsList', httpLeads, '', 'GET');

export const getLeadByIdThunk = thunkCreator<
  IAccountDTO,
  { accountId: number }
>('leads/getLeadByIdThunk', httpAccount, AccountRoutes.ById, 'GET');

export const getVerificationToken = thunkCreator<
  IDownloadTokenDTO,
  Record<string, undefined>
>('leads/getVerificationToken', httpLeads, LeadRoutes.TemplateToken, 'GET');

export const getCsvErrorsToken = thunkCreator<
  IDownloadTokenDTO,
  Record<string, undefined>
>('leads/getCsvErrorsToken', httpLeads, LeadRoutes.CsvErrorsToken, 'GET');

export const updateLeadsThunk = thunkCreator<boolean, IAssignLeadDTO[]>(
  'leads/updateLeadsThunk',
  httpLeads,
  '',
  'PUT',
);

export const getLeadDetails = thunkCreator<ILeadDTO, { leadId: number }>(
  'leads/getLeadDetails',
  httpLeads,
  LeadRoutes.ById,
  'GET',
);

export const getUploadedLeadsThunk = thunkCreator<
  IGetUploadedLeadsResponseDTO,
  RequestBaseType<UploadedLeadSortBy> & { uploadId: string }
>(
  'leads/getUploadedLeads',
  httpLeads,
  `${LeadRoutes.Uploads}/${LeadRoutes.UploadId}`,
  'GET',
);

export const getUploadAttachmentsLinkThunk = thunkCreator<
  ILinkDTO,
  IGetUploadAttachmentsLink
>(
  'leads/getLinkForUploadAttachment',
  httpLeads,
  LeadRoutes.GetUploadAttachmentsLink,
  'POST',
);

export const removeUploadedLeadsThunk = thunkCreator<
  boolean,
  { uploadId: string }
>(
  'leads/removeUploadedLeads',
  httpLeads,
  `${LeadRoutes.Uploads}/${LeadRoutes.UploadId}`,
  'DELETE',
);

export const applyUploadedLeadsThunk = thunkCreator<
  IGetUploadedLeadsResponseDTO & {
    errors: number;
    success: number;
    total: number;
  }, //TODO: Remove. They are should be in IGetUploadedLeadsResponseDTO
  IApplyUploadedLeadsRequestDTO & { uploadId: string }
>(
  'leads/applyUploadedLeads',
  httpLeads,
  `${LeadRoutes.Uploads}/${LeadRoutes.UploadId}`,
  'PUT',
);

export const getLeadChildAccounts = thunkCreator<
  IAvailableChildrenAccountsDTO,
  {
    accountId: number | undefined;
    accountType: AccountType;
    metaData: AccountType;
  }
>(
  'leads/getLeadChildAccountsThunk',
  httpAccount,
  AccountRoutes.AvailableChildren,
  'GET',
);

export const recieveTokenForExportLeadsAsCsvThunk = thunkCreator<
  IDownloadTokenDTO,
  IExportLeadsRequestDTO &
    Omit<IFilterLeadData, 'leadType'> & {
      onlyUncategorized?: boolean;
      searchQuery?: string;
    }
>(
  'leads/recieveTokenForExportLeadsAsCsvThunk',
  httpLeads,
  LeadRoutes.ExportToken,
  'POST',
);

export const getLeadAttacmentLinkThunk = thunkCreator<
  { link: string },
  { leadId: number }
>(
  'leads/getLeadAttacmentLinkThunk',
  httpLeads,
  LeadRoutes.GetDownloadAttachmentLink,
  'GET',
);

export const getDownloadBulkUploadErrorsLinkThunk = thunkCreator<
  { link: string },
  { bulkUploadId: string | null }
>(
  'leads/getDownloadBulkUploadErrorsLinkThunk',
  httpLeads,
  LeadRoutes.GetDownloadBulkUploadErrorsLink,
  'GET',
);

export const getBulkUploadResultThunk = createAsyncThunk<
  IBulkUploadDTO | null,
  { bulkUploadId: string },
  { rejectValue: IErrorDTO | null }
>(
  'leads/getBulkUploadResultThunk',
  async ({ bulkUploadId }, { rejectWithValue, dispatch }) => {
    const link = `/${LeadRoutes.GetUploadAttachmentsStatus}/${bulkUploadId}`;

    const fetchMethod = async () =>
      fetch(`${httpLeads}${link}`, {
        method: 'GET',
        headers: {
          Authorization: getBearerAccessToken(getAccessToken()),
          'Content-Type': 'application/json',
        },
      });

    while (1) {
      let response = await fetchMethod();

      if (response.status === 401) {
        try {
          const refreshResult = await dispatch(refreshTokenThunk()).unwrap();
          if (refreshResult?.account.id) {
            response = await fetchMethod();
          }
        } catch (e) {
          console.error(e);
          return rejectWithValue(null);
        }
      }

      const jsonData = await response.json();

      const successResult = jsonData as IBulkUploadDTO;

      const errorResult = jsonData as IApiResponseGenericDTO<IBulkUploadDTO>;

      if (
        errorResult.error &&
        (errorResult.statusCode !== 200 || !errorResult.success)
      ) {
        if (errorResult.statusCode === 401) {
          dispatch(signOut());
          return null;
        }

        return rejectWithValue(errorResult.error);
      }

      if (successResult.status === BulkUploadStatus.Finished) {
        return successResult;
      } else {
        await waitMs(5000);
      }
    }

    return null;
  },
);
