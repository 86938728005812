import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import {
  getAccountOwnThunk,
  createAccountThunk,
  getAccountByIdThunk,
  getChildAccounts,
  updateAccount,
  resendInviteThunk,
  deactivateAccThunk,
  activateAccThunk,
  resendForgotPasswordThunk,
  getMainAccounts,
  getAffiliatesListThunk,
  getMyAgentsListThunk,
  getAccountListThunk,
  getSuborninatorsListThunk,
} from '../thunks/accountThunk';
import { TAccountsReducerInitialState } from '../types/accountTypes';

export const initialState: TAccountsReducerInitialState = {
  getAccounts: {
    count: -1,
    pages: 0,
    page: 0,
    pageLimit: 0,
    accounts: [],
    isFetching: false,
    error: null,
  },

  getOwnAccounts: {
    parents: [],
    isFetching: false,
    error: null,
  },

  inviteAccount: {
    account: null,
    isLoading: false,
    isSending: false,
    error: null,
  },

  updateAccount: {
    account: null,
    isLoading: false,
    isUpdated: false,
    error: null,
  },

  deactivateAcc: {
    isLoading: false,
    isDeactivated: false,
    error: null,
  },

  activateAcc: {
    isLoading: false,
    isActivated: false,
    error: null,
  },

  resendInvite: {
    isLoading: false,
    isResended: false,
    error: null,
  },

  resendFP: {
    isPending: false,
    error: null,
    isEmailSent: false,
  },

  getAccount: {
    isFetching: false,
    isFulfilled: false,
    account: null,
    error: null,
  },

  getChildAccounts: {
    isFetching: false,
    accounts: [],
    error: null,
  },

  getMainAccounts: {
    isFetching: false,
    accounts: [],
    error: null,
  },

  parentAccountId: null,

  unexpectedError: null,
};

const AccountReducer = createSlice({
  name: 'Accounts',
  initialState,
  reducers: {
    refreshAccountsModalData(state) {
      state.inviteAccount.isSending = false;
      state.updateAccount.isUpdated = false;
      state.resendInvite.isResended = false;
      state.deactivateAcc.isDeactivated = false;
      state.activateAcc.isActivated = false;
      state.resendFP.isEmailSent = false;

      state.inviteAccount.isLoading = false;
      state.updateAccount.isLoading = false;
      state.resendInvite.isLoading = false;
      state.deactivateAcc.isLoading = false;
      state.activateAcc.isLoading = false;
      state.resendFP.isPending = false;

      state.parentAccountId = null;
      state.getAccount = initialState.getAccount;
    },
    resetGetAccountsError({
      getAccounts,
      getOwnAccounts,
      getAccount,
      getChildAccounts,
      inviteAccount,
      updateAccount,
      resendInvite,
      deactivateAcc,
      activateAcc,
      resendFP,
    }) {
      getAccounts.error = null;
      getOwnAccounts.error = null;
      getAccount.error = null;
      getChildAccounts.error = null;
      inviteAccount.error = null;
      updateAccount.error = null;
      resendInvite.error = null;
      deactivateAcc.error = null;
      activateAcc.error = null;
      resendFP.error = null;
    },
    setParentAccountId(state, action: PayloadAction<number>) {
      state.parentAccountId = action.payload;
    },
    resetGetAccountIsFulfilled({ getAccount }) {
      getAccount.isFulfilled = false;
    },
    resetIsDeactivated({ deactivateAcc }) {
      deactivateAcc.isDeactivated = false;
    },
    resetIsActivated({ activateAcc }) {
      activateAcc.isActivated = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAccountListThunk.pending, ({ getAccounts }) => {
        getAccounts.isFetching = true;
        getAccounts.accounts = [];
      })
      .addCase(getAccountListThunk.fulfilled, ({ getAccounts }, action) => {
        if (action.payload) {
          getAccounts.accounts = action.payload.accounts;
          getAccounts.count = action.payload.count;
          getAccounts.pages = action.payload.pages;
        }
        getAccounts.isFetching = false;
      })
      .addCase(getAccountListThunk.rejected, (state, action) => {
        if (state.getAccounts.isFetching) {
          state.getAccounts.isFetching = false;
        }
        if (action.payload) {
          // Since we passed in `MyKnownError` to `rejectValue` in `updateAccount`, the type information will be available here.
          state.getAccounts.error = action.payload;
        } else {
          state.unexpectedError = action.error;
        }
      })
      .addCase(getAffiliatesListThunk.pending, ({ getAccounts }) => {
        getAccounts.isFetching = true;
        getAccounts.accounts = [];
      })
      .addCase(getAffiliatesListThunk.fulfilled, ({ getAccounts }, action) => {
        if (action.payload) {
          getAccounts.accounts = action.payload.accounts;
          getAccounts.count = action.payload.count;
          getAccounts.pages = action.payload.pages;
        }
        getAccounts.isFetching = false;
      })
      .addCase(getAffiliatesListThunk.rejected, (state, action) => {
        if (state.getAccounts.isFetching) {
          state.getAccounts.isFetching = false;
        }
        if (action.payload) {
          // Since we passed in `MyKnownError` to `rejectValue` in `updateAccount`, the type information will be available here.
          state.getAccounts.error = action.payload;
        } else {
          state.unexpectedError = action.error;
        }
      })
      .addCase(getMyAgentsListThunk.pending, ({ getAccounts }) => {
        getAccounts.isFetching = true;
        getAccounts.accounts = [];
      })
      .addCase(getMyAgentsListThunk.fulfilled, ({ getAccounts }, action) => {
        if (action.payload) {
          getAccounts.accounts = action.payload.accounts;
          getAccounts.count = action.payload.count;
          getAccounts.pages = action.payload.pages;
        }
        getAccounts.isFetching = false;
      })
      .addCase(getMyAgentsListThunk.rejected, (state, action) => {
        if (state.getAccounts.isFetching) {
          state.getAccounts.isFetching = false;
        }
        if (action.payload) {
          // Since we passed in `MyKnownError` to `rejectValue` in `updateAccount`, the type information will be available here.
          state.getAccounts.error = action.payload;
        } else {
          state.unexpectedError = action.error;
        }
      })
      .addCase(getSuborninatorsListThunk.pending, ({ getAccounts }) => {
        getAccounts.isFetching = true;
        getAccounts.accounts = [];
      })
      .addCase(
        getSuborninatorsListThunk.fulfilled,
        ({ getAccounts }, action) => {
          if (action.payload) {
            getAccounts.accounts = action.payload.subordinates;
            getAccounts.count = action.payload.subordinates.length;
            getAccounts.pages = 1;
          }
          getAccounts.isFetching = false;
        },
      )
      .addCase(getSuborninatorsListThunk.rejected, (state, action) => {
        if (state.getAccounts.isFetching) {
          state.getAccounts.isFetching = false;
        }
        if (action.payload) {
          // Since we passed in `MyKnownError` to `rejectValue` in `updateAccount`, the type information will be available here.
          state.getAccounts.error = action.payload;
        } else {
          state.unexpectedError = action.error;
        }
      })
      .addCase(getAccountOwnThunk.pending, ({ getOwnAccounts }) => {
        getOwnAccounts.isFetching = true;
      })
      .addCase(getAccountOwnThunk.fulfilled, ({ getOwnAccounts }, action) => {
        getOwnAccounts.isFetching = false;

        if (action.payload) {
          getOwnAccounts.parents = action.payload.parents;
        }
      })
      .addCase(getAccountOwnThunk.rejected, (state, action) => {
        if (state.getOwnAccounts.isFetching) {
          state.getOwnAccounts.isFetching = false;
        }
        if (action.payload) {
          // Since we passed in `MyKnownError` to `rejectValue` in `updateAccount`, the type information will be available here.
          state.getOwnAccounts.error = action.payload;
        } else {
          state.unexpectedError = action.error;
        }
      })
      .addCase(getAccountByIdThunk.pending, ({ getAccount }) => {
        getAccount.isFetching = true;
      })
      .addCase(getAccountByIdThunk.fulfilled, ({ getAccount }, action) => {
        getAccount.isFetching = false;

        if (action.payload) {
          getAccount.account = action.payload;
        }

        getAccount.isFulfilled = true;
      })
      .addCase(getAccountByIdThunk.rejected, (state, action) => {
        if (state.getAccount.isFetching) {
          state.getAccount.isFetching = false;
        }
        if (state.getAccount.isFulfilled) {
          state.getAccount.isFulfilled = false;
        }
        if (action.payload) {
          // Since we passed in `MyKnownError` to `rejectValue` in `updateAccount`, the type information will be available here.
          state.getAccount.error = action.payload;
        } else {
          state.unexpectedError = action.error;
        }
      })
      .addCase(createAccountThunk.pending, ({ inviteAccount }) => {
        inviteAccount.isLoading = true;
      })
      .addCase(createAccountThunk.fulfilled, ({ inviteAccount }, action) => {
        inviteAccount.isLoading = false;

        if (action.payload) {
          inviteAccount.isSending = true;
          inviteAccount.account = action.payload;
        }
      })
      .addCase(createAccountThunk.rejected, (state, action) => {
        if (state.inviteAccount.isLoading) {
          state.inviteAccount.isLoading = false;
        }
        if (action.payload) {
          // Since we passed in `MyKnownError` to `rejectValue` in `updateAccount`, the type information will be available here.
          state.inviteAccount.error = action.payload;
        } else {
          state.unexpectedError = action.error;
        }
      })

      .addCase(getChildAccounts.pending, ({ getChildAccounts }) => {
        getChildAccounts.isFetching = true;
      })
      .addCase(getChildAccounts.fulfilled, ({ getChildAccounts }, action) => {
        if (action.payload) {
          getChildAccounts.accounts = action.payload.accounts;
        }

        getChildAccounts.isFetching = false;
      })
      .addCase(getChildAccounts.rejected, (state, action) => {
        if (state.getChildAccounts.isFetching) {
          state.getChildAccounts.isFetching = false;
        }
        if (action.payload) {
          state.getChildAccounts.error = action.payload;
        } else {
          state.unexpectedError = action.error;
        }
      })

      .addCase(getMainAccounts.pending, ({ getMainAccounts }) => {
        getMainAccounts.isFetching = true;
      })
      .addCase(getMainAccounts.fulfilled, ({ getMainAccounts }, action) => {
        getMainAccounts.isFetching = false;

        if (action.payload) {
          getMainAccounts.accounts = action.payload.accounts;
        }
      })
      .addCase(getMainAccounts.rejected, (state, action) => {
        if (state.getMainAccounts.isFetching) {
          state.getMainAccounts.isFetching = false;
        }
        if (action.payload) {
          state.getMainAccounts.error = action.payload;
        } else {
          state.unexpectedError = action.error;
        }
      })

      .addCase(updateAccount.pending, ({ updateAccount }) => {
        updateAccount.isLoading = true;
      })
      .addCase(updateAccount.fulfilled, ({ updateAccount }) => {
        updateAccount.isLoading = false;
        updateAccount.isUpdated = true;
      })
      .addCase(updateAccount.rejected, (state, action) => {
        if (state.updateAccount.isLoading) {
          state.updateAccount.isLoading = false;
        }
        if (action.payload) {
          state.updateAccount.error = action.payload;
        } else {
          state.unexpectedError = action.error;
        }
      })

      .addCase(resendInviteThunk.pending, ({ resendInvite }) => {
        resendInvite.isLoading = true;
      })
      .addCase(resendInviteThunk.fulfilled, ({ resendInvite }) => {
        resendInvite.isLoading = false;
        resendInvite.isResended = true;
      })
      .addCase(resendInviteThunk.rejected, (state, action) => {
        if (state.resendInvite.isLoading) {
          state.resendInvite.isLoading = false;
        }
        if (action.payload) {
          state.resendInvite.error = action.payload;
        } else {
          state.unexpectedError = action.error;
        }
      })

      .addCase(deactivateAccThunk.pending, ({ deactivateAcc }) => {
        deactivateAcc.isLoading = true;
      })
      .addCase(deactivateAccThunk.fulfilled, ({ deactivateAcc }) => {
        deactivateAcc.isLoading = false;
        deactivateAcc.isDeactivated = true;
      })
      .addCase(deactivateAccThunk.rejected, (state, action) => {
        if (state.deactivateAcc.isLoading) {
          state.deactivateAcc.isLoading = false;
        }
        if (action.payload) {
          state.deactivateAcc.error = action.payload;
        } else {
          state.unexpectedError = action.error;
        }
      })

      .addCase(activateAccThunk.pending, ({ activateAcc }) => {
        activateAcc.isLoading = true;
      })
      .addCase(activateAccThunk.fulfilled, ({ activateAcc }) => {
        activateAcc.isLoading = false;
        activateAcc.isActivated = true;
      })
      .addCase(activateAccThunk.rejected, (state, action) => {
        if (state.activateAcc.isLoading) {
          state.activateAcc.isLoading = false;
        }
        if (action.payload) {
          state.activateAcc.error = action.payload;
        } else {
          state.unexpectedError = action.error;
        }
      })

      .addCase(resendForgotPasswordThunk.pending, ({ resendFP }) => {
        resendFP.isPending = true;
        resendFP.error = null;
      })
      .addCase(resendForgotPasswordThunk.fulfilled, ({ resendFP }) => {
        resendFP.isPending = false;
        resendFP.isEmailSent = true;
      })
      .addCase(resendForgotPasswordThunk.rejected, (state, action) => {
        state.resendFP.isPending = false;
        if (action.payload) {
          state.resendFP.error = action.payload;
        } else {
          state.unexpectedError = action.error;
        }
      });
  },
});

export const {
  refreshAccountsModalData,
  resetGetAccountsError,
  setParentAccountId,
  resetGetAccountIsFulfilled,
  resetIsDeactivated,
  resetIsActivated,
} = AccountReducer.actions;

export default AccountReducer.reducer;
