import { createSlice } from '@reduxjs/toolkit';

import { TStatesReducerInitialState } from '../types/statesTypes';
import { getCountiesListThunk, getStateListThunk } from '../thunks/statesThunk';
import { convertToSelectOptions } from '../../utils/SelectOptionsUtil';
import { initAccountOption } from '../../components/LeadsFilter/utils';
import { getCountiesMapByName, getStatesAbreviature } from '../utils';

export const initialState: TStatesReducerInitialState = {
  getStates: {
    states: [],
    statesFilter: [],
    shortStates: null,
    isFetching: false,
    error: null,
  },

  getCounties: {
    counties: [],
    countiesFilter: [],
    countiesMap: null,
    isFetching: false,
    error: null,
  },

  filterAttachmentOptions: [
    initAccountOption,
    { value: 'true', label: 'Uploaded' },
    { value: 'false', label: 'Not uploaded' },
  ],

  unexpectedError: null,
};

const SettingsReducer = createSlice({
  name: 'Settings',
  initialState,
  reducers: {
    refreshCountiesData(state) {
      state.getCounties = initialState.getCounties;
    },
    resetGetStatesError({ getStates, getCounties }) {
      getStates.error = null;
      getCounties.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getStateListThunk.pending, ({ getStates }) => {
        getStates.isFetching = true;
        getStates.states = [];
        getStates.statesFilter = [];
        getStates.shortStates = null;
      })
      .addCase(getStateListThunk.fulfilled, ({ getStates }, action) => {
        getStates.isFetching = false;
        if (action.payload) {
          getStates.states = action.payload.states;
          getStates.statesFilter = convertToSelectOptions(
            action.payload.states,
            initAccountOption,
          );
          getStates.shortStates = getStatesAbreviature(action.payload.states);
        }
      })
      .addCase(getStateListThunk.rejected, (state, action) => {
        if (state.getStates.isFetching) {
          state.getStates.isFetching = false;
        }
        if (action.payload) {
          state.getStates.error = action.payload;
        } else {
          state.unexpectedError = action.error;
        }
      })

      .addCase(getCountiesListThunk.pending, ({ getCounties }) => {
        getCounties.isFetching = true;
        getCounties.counties = [];
        getCounties.countiesFilter = [];
        getCounties.countiesMap = null;
      })
      .addCase(getCountiesListThunk.fulfilled, ({ getCounties }, action) => {
        getCounties.isFetching = false;
        if (action.payload) {
          getCounties.counties = action.payload.counties;
          getCounties.countiesFilter = convertToSelectOptions(
            action.payload.counties,
          );
          getCounties.countiesMap = getCountiesMapByName(
            action.payload.counties,
          );
        }
      })
      .addCase(getCountiesListThunk.rejected, (state, action) => {
        if (state.getCounties.isFetching) {
          state.getCounties.isFetching = false;
        }
        if (action.payload) {
          state.getCounties.error = action.payload;
        } else {
          state.unexpectedError = action.error;
        }
      });
  },
});

export const { refreshCountiesData, resetGetStatesError } =
  SettingsReducer.actions;

export default SettingsReducer.reducer;
