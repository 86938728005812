import { createSlice } from '@reduxjs/toolkit';
import { FoldersInitState } from '../types/foldersTypes';
import {
  createFolderThunk,
  deleteFolderThunk,
  getActiveFoldersThunk,
  getFolderByIdThunk,
  getFoldersThunk,
  updateFolderThunk,
} from '../thunks/foldersThunk';

export const initialState: FoldersInitState = {
  getActiveFolders: {
    folders: [],
    isFetching: false,
    error: null,
  },

  getAllFolders: {
    folders: [],
    isFetching: false,
    error: null,
  },

  createFolder: {
    folder: null,
    isLoading: false,
    isCreated: false,
    error: null,
  },

  updateFolder: {
    folder: null,
    isLoading: false,
    isUpdated: false,
    isMoved: false,
    error: null,
  },

  deleteFolder: {
    isLoading: false,
    isDeleted: false,
    error: null,
  },

  getFolder: {
    isFetching: false,
    isFulfilled: false,
    folder: null,
    error: null,
  },

  unexpectedError: null,
};

const FoldersReducer = createSlice({
  name: 'Folders',
  initialState,
  reducers: {
    resetFoldersError({
      getActiveFolders,
      getAllFolders,
      createFolder,
      deleteFolder,
      getFolder,
    }) {
      getActiveFolders.error = null;
      getAllFolders.error = null;
      createFolder.error = null;
      deleteFolder.error = null;
      getFolder.error = null;
    },
    resetFolderModal(state) {
      state.createFolder = initialState.createFolder;
      state.updateFolder = initialState.updateFolder;
      state.deleteFolder = initialState.deleteFolder;
    },
    resetDeleteFolderModal(state) {
      state.deleteFolder = initialState.deleteFolder;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getActiveFoldersThunk.pending, ({ getActiveFolders }) => {
        getActiveFolders.isFetching = true;
      })
      .addCase(
        getActiveFoldersThunk.fulfilled,
        ({ getActiveFolders }, action) => {
          getActiveFolders.isFetching = false;
          if (action.payload) {
            getActiveFolders.folders = action.payload.folders;
          } else {
            getActiveFolders.folders = [];
          }
        },
      )
      .addCase(getActiveFoldersThunk.rejected, (state, action) => {
        if (state.getActiveFolders.isFetching) {
          state.getActiveFolders.isFetching = false;
          state.getActiveFolders.folders = [];
        }
        if (action.payload) {
          state.getAllFolders.error = action.payload;
        } else {
          state.unexpectedError = action.error;
        }
      })
      .addCase(getFoldersThunk.pending, ({ getAllFolders }) => {
        getAllFolders.isFetching = true;
      })
      .addCase(getFoldersThunk.fulfilled, ({ getAllFolders }, action) => {
        getAllFolders.isFetching = false;
        if (action.payload) {
          getAllFolders.folders = action.payload.folders;
        } else {
          getAllFolders.folders = [];
        }
      })
      .addCase(getFoldersThunk.rejected, (state, action) => {
        if (state.getAllFolders.isFetching) {
          state.getAllFolders.isFetching = false;
          state.getAllFolders.folders = [];
        }
        if (action.payload) {
          state.getAllFolders.error = action.payload;
        } else {
          state.unexpectedError = action.error;
        }
      })
      .addCase(getFolderByIdThunk.pending, ({ getFolder }) => {
        getFolder.isFetching = true;
      })
      .addCase(getFolderByIdThunk.fulfilled, ({ getFolder }, action) => {
        getFolder.isFetching = false;

        if (action.payload) {
          getFolder.folder = action.payload;
        }

        getFolder.isFulfilled = true;
      })
      .addCase(getFolderByIdThunk.rejected, (state, action) => {
        if (state.getFolder.isFetching) {
          state.getFolder.isFetching = false;
        }
        if (state.getFolder.isFulfilled) {
          state.getFolder.isFulfilled = false;
        }
        if (action.payload) {
          state.getFolder.error = action.payload;
        } else {
          state.unexpectedError = action.error;
        }
      })
      .addCase(createFolderThunk.pending, ({ createFolder }) => {
        createFolder.isLoading = true;
      })
      .addCase(createFolderThunk.fulfilled, ({ createFolder }, action) => {
        createFolder.isLoading = false;

        if (action.payload) {
          createFolder.isCreated = true;
          createFolder.folder = action.payload;
        }
      })
      .addCase(createFolderThunk.rejected, (state, action) => {
        if (state.createFolder.isLoading) {
          state.createFolder.isLoading = false;
        }
        if (action.payload) {
          state.createFolder.error = action.payload;
        } else {
          state.unexpectedError = action.error;
        }
      })

      .addCase(updateFolderThunk.pending, ({ updateFolder }) => {
        updateFolder.isLoading = true;
      })
      .addCase(updateFolderThunk.fulfilled, ({ updateFolder }, action) => {
        updateFolder.isLoading = false;

        if (action.payload) {
          if (action.meta.arg.order !== undefined) {
            updateFolder.isMoved = true;
          } else {
            updateFolder.isUpdated = true;
          }
          updateFolder.folder = action.payload;
        }
      })
      .addCase(updateFolderThunk.rejected, (state, action) => {
        if (state.updateFolder.isLoading) {
          state.updateFolder.isLoading = false;
        }
        if (action.payload) {
          state.updateFolder.error = action.payload;
        } else {
          state.unexpectedError = action.error;
        }
      })

      .addCase(deleteFolderThunk.pending, ({ deleteFolder }) => {
        deleteFolder.isLoading = true;
      })
      .addCase(deleteFolderThunk.fulfilled, ({ deleteFolder }) => {
        deleteFolder.isLoading = false;
        deleteFolder.isDeleted = true;
      })
      .addCase(deleteFolderThunk.rejected, (state, action) => {
        if (state.deleteFolder.isLoading) {
          state.deleteFolder.isLoading = false;
        }
        if (action.payload) {
          state.deleteFolder.error = action.payload;
        } else {
          state.unexpectedError = action.error;
        }
      });
  },
});

export const { resetFoldersError, resetFolderModal, resetDeleteFolderModal } =
  FoldersReducer.actions;

export default FoldersReducer.reducer;
