import React from 'react';

import './style.scss';

//TODO: fix loader
const Loader: React.FC = () => {
  return (
    <div className="loader-wrap">
      <span className="loader"></span>
      <span className="title">Loading</span>
    </div>
  );
};

export default Loader;
