import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { store } from './redux/store';
import { I18nextProvider } from 'react-i18next';
import i18n from './i18n/i18n';
import { SWRConfig } from 'swr';

import ErrorBoundary from './components/ErrorBoundary';
import App from './App';
import { getBearerAccessToken } from './api';
import { getAccessToken } from './utils/StorageUtil';

const container = document.getElementById('root')!;
const root = createRoot(container);

root.render(
  // <React.StrictMode>
  <SWRConfig
    value={{
      fetcher: (url: string) =>
        fetch(url, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: getBearerAccessToken(getAccessToken()),
          },
        }).then((res) => res.json()),
    }}
  >
    <Provider store={store}>
      <I18nextProvider i18n={i18n}>
        <ErrorBoundary>
          <BrowserRouter>
            <App />
          </BrowserRouter>
        </ErrorBoundary>
      </I18nextProvider>
    </Provider>
  </SWRConfig>,

  // </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
