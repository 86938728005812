import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router';

import RouterComponent from './components/RouterComponent';
import Loader from './components/Loader';

import { refreshTokenThunk } from './redux/thunks/authThunk';
import { EToken } from './redux/types';
import { getStateListThunk } from './redux/thunks/statesThunk';
import { getFoldersThunk } from './redux/thunks/foldersThunk';
import { signOut } from './redux/reducers/authReducer';

import { useAppDispatch, useAppSelector } from './hooks/hooks';
import { checkStorage, getStorage, setStorage } from './utils/StorageUtil';
import { linksForSignOut, LoginUrl } from './UrlsConfig';
import { NODE_ENV } from './SetupConfig';

import './assets/scss/index.scss';

const App: React.FunctionComponent = () => {
  const [isApplicationLoading, setIsApplicationLoading] = useState(true);
  const {
    tokenIsRefreshing,
    tokenRefreshFailed,
    isUserLoggedFromLoginPage,
    isUserLogged,
    account,
  } = useAppSelector(({ auth }) => auth);
  const { pathname } = useLocation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (checkStorage(EToken.TOKENS)) {
      const isNotAuthLink = linksForSignOut.some((link) =>
        pathname.includes(link),
      );

      if (isNotAuthLink) {
        dispatch(signOut());
        setIsApplicationLoading(false);
        return;
      }

      dispatch(refreshTokenThunk(true));
      return;
    }
    setIsApplicationLoading(false);
  }, []);

  useEffect(() => {
    if (tokenIsRefreshing && isApplicationLoading) {
      setIsApplicationLoading(false);
    }
  }, [tokenIsRefreshing]);

  useEffect(() => {
    if (tokenRefreshFailed) {
      navigate(LoginUrl);
      setIsApplicationLoading(false);
    }
  }, [tokenRefreshFailed]);

  useEffect(() => {
    if (NODE_ENV && NODE_ENV === 'local') {
      return;
    }

    window.addEventListener('focus', pageFocusHandler);
    return () => {
      window.removeEventListener('focus', pageFocusHandler);
    };
  }, []);

  useEffect(() => {
    if (isUserLoggedFromLoginPage) {
      setStorage('shouldRefresh', true);
    }
  }, [isUserLoggedFromLoginPage]);

  useEffect(() => {
    if (isUserLogged) {
      dispatch(getStateListThunk({}));
      dispatch(getFoldersThunk({}));
    }
  }, [isUserLogged, account.accountType]);

  const pageFocusHandler = () => {
    if (checkStorage(EToken.TOKENS)) {
      const shouldRefresh = getStorage('shouldRefresh');
      if (!shouldRefresh) {
        return;
      }

      setStorage('shouldRefresh', false);
      setIsApplicationLoading(true);
      dispatch(refreshTokenThunk(true));
    } else {
      dispatch(signOut());
    }
  };

  return <>{!isApplicationLoading ? <RouterComponent /> : <Loader />}</>;
};

export default App;
