import { createSlice } from '@reduxjs/toolkit';

import { TDashboardReducerInitialState } from '../types/dashboardTypes';
import {
  getMapInfoThunk,
  getStateInfoThunk,
  getLeadsMetricInfoThunk,
  getMapAffiliatesInfoThunk,
  getMapAgentInfoThunk,
  getTopLeadsInfoThunk,
  getAdminLeadsStatisticInfoThunk,
} from '../thunks/dashboardThunk';
import { getCountiesFormattedMap } from '../utils';
import { AccountType } from '../../common/enums/account/account-type.enum';

export const initialState: TDashboardReducerInitialState = {
  getAdminStatistic: {
    totalLeads: 0,
    topLeads: [],
    leadsByType: null,
    isFetching: false,
    isTotalLeads: false,
    error: null,
  },

  getTopLeadsByAccountType: {
    topLeadsByAccountType: {
      [AccountType.Admin]: [],
      [AccountType.MainAccount]: [],
      [AccountType.SubAccount]: [],
      [AccountType.Agent]: [],
      [AccountType.Restricted]: [],
    },
  },

  getMetrics: {
    metrics: null,
    isFetching: false,
    error: null,
  },

  getLeadsForMap: {
    leads: null,
    isFetching: false,
    error: null,
  },

  getLeadsForState: {
    stateLeads: null,
    isFetching: false,
    error: null,
  },

  unexpectedError: null,
};

const DashboardReducer = createSlice({
  name: 'Settings',
  initialState,
  reducers: {
    refreshDashboardData(state) {
      state.getMetrics = initialState.getMetrics;
      state.getLeadsForMap = initialState.getLeadsForMap;
      state.getLeadsForState = initialState.getLeadsForState;
    },
    resetDashboardError({ getMetrics, getLeadsForState, getLeadsForMap }) {
      getMetrics.error = null;
      getLeadsForState.error = null;
      getLeadsForMap.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(
        getAdminLeadsStatisticInfoThunk.pending,
        ({ getAdminStatistic }) => {
          getAdminStatistic.isFetching = true;
          getAdminStatistic.leadsByType = null;
          getAdminStatistic.totalLeads = 0;
        },
      )
      .addCase(
        getAdminLeadsStatisticInfoThunk.fulfilled,
        ({ getAdminStatistic }, action) => {
          getAdminStatistic.isFetching = false;
          if (action.payload) {
            getAdminStatistic.leadsByType = action.payload.received;
            getAdminStatistic.isTotalLeads = Object.values(
              getAdminStatistic.leadsByType['total'],
            ).some((value) => +value > 0);
          }
        },
      )
      .addCase(getAdminLeadsStatisticInfoThunk.rejected, (state, action) => {
        if (state.getAdminStatistic.isFetching) {
          state.getAdminStatistic.isFetching = false;
        }
        if (action.payload) {
          state.getAdminStatistic.error = action.payload;
        } else {
          state.unexpectedError = action.error;
        }
      })
      .addCase(getLeadsMetricInfoThunk.pending, ({ getMetrics }) => {
        getMetrics.isFetching = true;
        getMetrics.metrics = null;
      })
      .addCase(getLeadsMetricInfoThunk.fulfilled, ({ getMetrics }, action) => {
        getMetrics.isFetching = false;
        if (action.payload) {
          getMetrics.metrics = action.payload.metrics;
        }
      })
      .addCase(getLeadsMetricInfoThunk.rejected, (state, action) => {
        if (state.getMetrics.isFetching) {
          state.getMetrics.isFetching = false;
        }
        if (action.payload) {
          state.getMetrics.error = action.payload;
        } else {
          state.unexpectedError = action.error;
        }
      })
      .addCase(getTopLeadsInfoThunk.pending, ({ getAdminStatistic }) => {
        getAdminStatistic.isFetching = true;
        getAdminStatistic.topLeads = [];
        getAdminStatistic.totalLeads = 0;
      })
      .addCase(
        getTopLeadsInfoThunk.fulfilled,
        ({ getAdminStatistic, getTopLeadsByAccountType }, action) => {
          getAdminStatistic.isFetching = false;
          if (action.payload) {
            const { top, totalLeads, accountType } = action.payload;
            if (top.length) {
              top.slice(0, 5).forEach((item, i) => {
                getAdminStatistic.topLeads[i] = item;
                if (accountType) {
                  getTopLeadsByAccountType.topLeadsByAccountType[accountType][
                    i
                  ] = item;
                }
              });
            } else {
              getAdminStatistic.topLeads = [];
              if (accountType) {
                getTopLeadsByAccountType.topLeadsByAccountType[accountType] =
                  [];
              }
            }
            getAdminStatistic.totalLeads = +totalLeads;
          }
        },
      )
      .addCase(getTopLeadsInfoThunk.rejected, (state, action) => {
        if (state.getAdminStatistic.isFetching) {
          state.getAdminStatistic.isFetching = false;
        }
        if (action.payload) {
          state.getAdminStatistic.error = action.payload;
        } else {
          state.unexpectedError = action.error;
        }
      })
      .addCase(getMapInfoThunk.pending, ({ getLeadsForMap }) => {
        getLeadsForMap.isFetching = true;
        getLeadsForMap.leads = null;
      })
      .addCase(getMapInfoThunk.fulfilled, ({ getLeadsForMap }, action) => {
        getLeadsForMap.isFetching = false;
        if (action.payload) {
          getLeadsForMap.leads = action.payload.states;
        }
      })
      .addCase(getMapInfoThunk.rejected, (state, action) => {
        if (state.getLeadsForMap.isFetching) {
          state.getLeadsForMap.isFetching = false;
        }
        if (action.payload) {
          state.getLeadsForMap.error = action.payload;
        } else {
          state.unexpectedError = action.error;
        }
      })
      .addCase(getMapAffiliatesInfoThunk.pending, ({ getLeadsForMap }) => {
        getLeadsForMap.isFetching = true;
        getLeadsForMap.leads = null;
      })
      .addCase(
        getMapAffiliatesInfoThunk.fulfilled,
        ({ getLeadsForMap }, action) => {
          getLeadsForMap.isFetching = false;
          if (action.payload) {
            getLeadsForMap.leads = action.payload.states;
          }
        },
      )
      .addCase(getMapAffiliatesInfoThunk.rejected, (state, action) => {
        if (state.getLeadsForMap.isFetching) {
          state.getLeadsForMap.isFetching = false;
        }
        if (action.payload) {
          state.getLeadsForMap.error = action.payload;
        } else {
          state.unexpectedError = action.error;
        }
      })
      .addCase(getMapAgentInfoThunk.pending, ({ getLeadsForMap }) => {
        getLeadsForMap.isFetching = true;
        getLeadsForMap.leads = null;
      })
      .addCase(getMapAgentInfoThunk.fulfilled, ({ getLeadsForMap }, action) => {
        getLeadsForMap.isFetching = false;
        if (action.payload) {
          getLeadsForMap.leads = action.payload.states;
        }
      })
      .addCase(getMapAgentInfoThunk.rejected, (state, action) => {
        if (state.getLeadsForMap.isFetching) {
          state.getLeadsForMap.isFetching = false;
        }
        if (action.payload) {
          state.getLeadsForMap.error = action.payload;
        } else {
          state.unexpectedError = action.error;
        }
      })
      .addCase(getStateInfoThunk.pending, ({ getLeadsForState }) => {
        getLeadsForState.isFetching = true;
        getLeadsForState.stateLeads = null;
      })
      .addCase(getStateInfoThunk.fulfilled, ({ getLeadsForState }, action) => {
        getLeadsForState.isFetching = false;
        if (action.payload) {
          getLeadsForState.stateLeads = getCountiesFormattedMap(
            action.payload.counties,
          );
        }
      })
      .addCase(getStateInfoThunk.rejected, (state, action) => {
        if (state.getLeadsForState.isFetching) {
          state.getLeadsForState.isFetching = false;
        }
        if (action.payload) {
          state.getLeadsForState.error = action.payload;
        } else {
          state.unexpectedError = action.error;
        }
      });
  },
});

export const { refreshDashboardData, resetDashboardError } =
  DashboardReducer.actions;

export default DashboardReducer.reducer;
