import { AccountRoutes } from '../../common/enums/routes/account-routes.enum';
import { IAccountDTO } from '../../common/interfaces/dto/account/iaccount.interface';
import { IGetAccountsResponseDTO } from '../../common/interfaces/dto/account/iget-accounts-response.interface';
import { IAccountReinviteDTO } from '../../common/interfaces/dto/account/iaccount-reinvite.onterface';
import { IUpdateAccountRequestDTO } from '../../common/interfaces/dto/account/iupdate-account.interface';
import { AccountStatus } from '../../common/enums/account/account-status.enum';
import { IRecoverPasswordRequestDTO } from '../../common/interfaces/dto/auth/irecover-password-request.interface';
import { AuthRoutes } from '../../common/enums/routes/auth-routes.enum';
import { IAccountCreationDTO } from '../../common/interfaces/dto/account/iaccount-creation.interface';
import { IAvailableChildrenAccountsDTO } from '../../common/interfaces/dto/account/iavailable-children-accounts.interface';
import { IAvailableParentAccountsDTO } from '../../common/interfaces/dto/account/iavailable-parent-accounts.interface';
import { AccountSortBy } from '../../common/enums/account/account-sort-by.enum';

import { httpAccount, httpAuth } from '../../api';
import { thunkCreator } from '../../utils/ThunkCreator';
import { RequestBaseType } from '../../hooks/useSerializedParams';
import { ISubordinatesDTO } from '../../common/interfaces/dto/account/isubordinates.interface';

export const getAccountListThunk = thunkCreator<
  IGetAccountsResponseDTO,
  RequestBaseType<AccountSortBy> & {
    parentAccountId?: number;
    status?: AccountStatus;
  }
>('accounts/getAccountList', httpAccount, '', 'GET');

export const getAffiliatesListThunk = thunkCreator<
  IGetAccountsResponseDTO,
  RequestBaseType<AccountSortBy>
>(
  'accounts/getAffiliatesList',
  httpAccount,
  AccountRoutes.GetAffiliates,
  'GET',
);

export const getMyAgentsListThunk = thunkCreator<
  IGetAccountsResponseDTO,
  RequestBaseType<AccountSortBy>
>('accounts/getMyAgentsList', httpAccount, AccountRoutes.GetAgents, 'GET');

export const getAccountByIdThunk = thunkCreator<
  IAccountDTO,
  { accountId: number }
>('accounts/getAccountById', httpAccount, AccountRoutes.ById, 'GET');

export const getSuborninatorsListThunk = thunkCreator<
  ISubordinatesDTO,
  {
    search: string;
  }
>(
  'accounts/getSuborninatorsList',
  httpAccount,
  AccountRoutes.Subordinates,
  'GET',
);

export const getAccountOwnThunk = thunkCreator<
  IAvailableParentAccountsDTO,
  Partial<RequestBaseType<AccountSortBy>> & { accountType: string }
>('accounts/getAccountOwn', httpAccount, AccountRoutes.AvailableParents, 'GET');

export const createAccountThunk = thunkCreator<
  IAccountDTO,
  IAccountCreationDTO
>('accounts/createAccount', httpAccount, '', 'POST');

export const getChildAccounts = thunkCreator<
  IAvailableChildrenAccountsDTO,
  Partial<RequestBaseType<AccountSortBy>> & { accountId: number }
>(
  'accounts/getChildAccounts',
  httpAccount,
  AccountRoutes.AvailableChildren,
  'GET',
);

export const getMainAccounts = thunkCreator<
  IAvailableChildrenAccountsDTO,
  { accountId: number | null }
>(
  'accounts/getMainAccounts',
  httpAccount,
  AccountRoutes.AvailableChildren,
  'GET',
);

export const updateAccount = thunkCreator<
  IAccountDTO,
  IUpdateAccountRequestDTO & { accountId?: number }
>('accounts/updateAccount', httpAccount, AccountRoutes.ById, 'PUT');

export const deactivateAccThunk = thunkCreator<
  IAccountDTO,
  { status: AccountStatus.Disabled; email: string; accountId?: number }
>('deactivate/deactivateAcc', httpAccount, AccountRoutes.ById, 'PUT');

export const activateAccThunk = thunkCreator<
  IAccountDTO,
  { status: AccountStatus.Active; email: string; accountId?: number }
>('activate/activateAcc', httpAccount, AccountRoutes.ById, 'PUT');

export const resendForgotPasswordThunk = thunkCreator<
  IAccountDTO,
  IRecoverPasswordRequestDTO
>('resendFPReducer/resendFP', httpAuth, AuthRoutes.PasswordRecovery, 'POST');

export const resendInviteThunk = thunkCreator<undefined, IAccountReinviteDTO>(
  'accounts/resendInvite',
  httpAccount,
  AccountRoutes.ResendInvite,
  'POST',
);
