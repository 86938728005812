import { httpSettings } from '../../api';
import { SettingRoutes } from '../../common/enums/routes/setting-routes.enum';
import { IGetIntegrationsResponseDTO } from '../../common/interfaces/dto/integration/get-integrations-response.interface';
import { IIntegrationCreationDTO } from '../../common/interfaces/dto/integration/iintegration-creation.interface';
import { IIntegrationDTO } from '../../common/interfaces/dto/integration/iintegration.interface';
import { IDuplicateCheckSettingsUpdationDTO } from '../../common/interfaces/dto/setting/iduplicate-check-settings-updation.interface';
import { IDuplicateCheckSettingsDTO } from '../../common/interfaces/dto/setting/iduplicate-check-settings.interface';
import { thunkCreator } from '../../utils/ThunkCreator';

export const getIntegrationListThunk = thunkCreator<
  IGetIntegrationsResponseDTO,
  Record<string, undefined>
>(
  'settings/getIntegrationsList',
  httpSettings,
  SettingRoutes.GetIntegrations,
  'GET',
);

export const createIntegrationThunk = thunkCreator<
  IIntegrationDTO,
  IIntegrationCreationDTO
>(
  'settings/createIntegration',
  httpSettings,
  SettingRoutes.CreateIntegration,
  'POST',
);

export const updateIntegrationThunk = thunkCreator<
  IIntegrationDTO,
  IIntegrationCreationDTO & { integrationId: number }
>(
  'settings/updateIntegration',
  httpSettings,
  SettingRoutes.UpdateIntegration,
  'PUT',
);

export const deleteIntegrationThunk = thunkCreator<
  boolean,
  { integrationId: number }
>(
  'settings/deleteIntegration',
  httpSettings,
  SettingRoutes.DeleteIntegration,
  'DELETE',
);

export const generateIntegrationSecretThunk = thunkCreator<
  IIntegrationDTO,
  { integrationId: number }
>(
  'settings/generateIntegrationSecret',
  httpSettings,
  SettingRoutes.GenerateIntegrationSecret,
  'PUT',
);

export const getDuplicatesThunk = thunkCreator<
  IDuplicateCheckSettingsDTO,
  { accountId: number }
>(
  'settings/getDuplicates',
  httpSettings,
  SettingRoutes.GetDuplicatesSettings,
  'GET',
);

export const updateDuplicatesThunk = thunkCreator<
  IDuplicateCheckSettingsDTO,
  IDuplicateCheckSettingsUpdationDTO & { accountId: number }
>(
  'settings/updateDuplicates',
  httpSettings,
  SettingRoutes.UpdateDuplicatesSettings,
  'PUT',
);
