export enum EMessageType {
  SUCCESS = 'success',
  ERROR = 'error',
  WARNING = 'warning',
  INFO = 'info',
}

export enum EModalType {
  CREATED = 'created',
  UPDATED = 'updated',
  REMOVED = 'removed',
}
