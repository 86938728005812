import {
  AccountsUrl,
  DashboardUrl,
  LeadsUrl,
  SettingsUrl,
  AffiliatesUrl,
  MyAgentsUrl,
  UncategorizedLeadsUrl,
  FoldersUrl,
  ReportsUrl,
} from '../../UrlsConfig';
import { AccountType } from '../../common/enums/account/account-type.enum';
import { INavCell } from './types';

export const initPagination = '?pageSize=100&pageNumber=1';

export const navbarArr: INavCell[] = [
  {
    id: 1,
    className: 'icon-home',
    title: 'Dashboard',
    linkAddress: DashboardUrl,
    role: [
      AccountType.Admin,
      AccountType.MainAccount,
      AccountType.SubAccount,
      AccountType.Agent,
    ],
  },
  {
    id: 2,
    className: 'icon-branch',
    title: 'Affiliates',
    linkAddress: AffiliatesUrl,
    role: [AccountType.MainAccount],
  },
  {
    id: 3,
    className: 'icon-users',
    title: 'Accounts',
    linkAddress: `${AccountsUrl}${initPagination}`,
    role: [AccountType.Admin],
  },
  {
    id: 4,
    className: 'icon-filter',
    title: 'Leads',
    role: [],
    icon: 'icon-chevron-down',
    subMenu: [
      {
        id: 41,
        title: 'Unassigned',
        linkAddress: `${UncategorizedLeadsUrl}${initPagination}`,
      },
      {
        id: 42,
        title: 'All leads',
        linkAddress: `${LeadsUrl}${initPagination}`,
      },
    ],
  },
  {
    id: 5,
    className: 'icon-folder',
    title: 'Folders',
    linkAddress: FoldersUrl,
    role: [],
  },
  {
    id: 6,
    className: 'icon-folder-dot',
    title: 'My Agents',
    linkAddress: MyAgentsUrl,
    role: [AccountType.SubAccount, AccountType.MainAccount],
  },
  {
    id: 7,
    className: 'icon-settings',
    title: 'Settings',
    linkAddress: SettingsUrl,
    role: [AccountType.Admin],
  },
  {
    id: 8,
    className: 'icon-settings',
    title: 'Reports',
    linkAddress: `${ReportsUrl}${initPagination}`,
    role: [AccountType.MainAccount],
  },
];
