import { createSlice } from '@reduxjs/toolkit';
import { IAccountDTO } from '../../common/interfaces/dto/account/iaccount.interface';
import {
  checkInvitationLinkThunk,
  checkResetPasswordLinkThunk,
  completeRegistrationThunk,
  forgotPasswordThunk,
  refreshTokenThunk,
  resetPasswordThunk,
  signInThunk,
} from '../thunks/authThunk';
import { ELinkType, IInitialState } from '../types/authTypes';
import { removeStorage } from '../../utils/StorageUtil';
import { EToken } from '../types';

export const initialState: IInitialState = {
  isUserLogged: false,
  isUserLoggedFromLoginPage: false,
  account: {} as IAccountDTO,
  isPending: false,
  error: null,
  unexpectedError: null,
  isEmailSend: false,
  isPasswordChange: false,
  isProfileInfoChange: false,
  tokenIsRefreshing: false,
  tokenRefreshFailed: false,

  checkIsLinkExpired: {
    error: null,
    linkType: '',
  },

  completeRegistration: {
    isCompleted: false,
    isLoading: false,
    error: null,
  },
};

const AuthReducer = createSlice({
  name: 'authReducer',
  initialState,
  reducers: {
    isEmailSendFalse(state) {
      state.isEmailSend = false;
    },
    refreshProfileInfoModalData(state) {
      state.error = null;
      state.unexpectedError = null;
      state.isProfileInfoChange = false;
      state.isPasswordChange = false;
      state.isPending = false;
    },
    refreshCompleteRegistration(state) {
      state.completeRegistration = initialState.completeRegistration;
    },
    refreshLinkExpired(state) {
      state.checkIsLinkExpired = initialState.checkIsLinkExpired;
    },
    signOut(state) {
      state.isUserLogged = false;
      removeStorage(EToken.TOKENS);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(signInThunk.pending, (state) => {
        state.isPending = true;
        state.error = null;
        state.tokenIsRefreshing = false;
        state.tokenRefreshFailed = false;
      })
      .addCase(signInThunk.fulfilled, (state, action) => {
        state.isPending = false;
        state.isUserLogged = true;
        state.isUserLoggedFromLoginPage = true;
        state.account = action.payload.account;
      })
      .addCase(signInThunk.rejected, (state, action) => {
        state.isPending = false;
        if (action.payload) {
          state.error = action.payload;
        } else {
          state.unexpectedError = action.error;
        }
      })
      .addCase(forgotPasswordThunk.pending, (state) => {
        state.isPending = true;
        state.error = null;
        state.isEmailSend = false;
      })
      .addCase(forgotPasswordThunk.fulfilled, (state, action) => {
        state.isPending = false;
        state.isEmailSend = action.payload;
      })
      .addCase(forgotPasswordThunk.rejected, (state, action) => {
        state.isPending = false;
        state.isEmailSend = false;
        if (action.payload) {
          state.error = action.payload;
        } else {
          state.unexpectedError = action.error;
        }
      })
      .addCase(resetPasswordThunk.pending, (state) => {
        state.isPending = true;
        state.error = null;
        state.isPasswordChange = false;
      })
      .addCase(resetPasswordThunk.fulfilled, (state, action) => {
        state.isPending = false;
        state.isPasswordChange = action.payload;
      })
      .addCase(resetPasswordThunk.rejected, (state, action) => {
        state.isPending = false;
        state.isPasswordChange = false;
        if (action.payload) {
          state.error = action.payload;
        } else {
          state.unexpectedError = action.error;
        }
      })
      .addCase(
        completeRegistrationThunk.pending,
        ({ completeRegistration }) => {
          completeRegistration.isLoading = true;
          completeRegistration.error = null;
          completeRegistration.isCompleted = false;
        },
      )
      .addCase(
        completeRegistrationThunk.fulfilled,
        ({ completeRegistration }, action) => {
          completeRegistration.isLoading = false;
          completeRegistration.isCompleted = action.payload;
        },
      )
      .addCase(completeRegistrationThunk.rejected, (state, action) => {
        state.completeRegistration.isLoading = false;
        state.completeRegistration.isCompleted = false;
        if (action.payload) {
          state.completeRegistration.error = action.payload;
        } else {
          state.unexpectedError = action.error;
        }
      })
      .addCase(refreshTokenThunk.pending, (state) => {
        state.isPending = true;
        state.error = null;
        state.tokenIsRefreshing = false;
        state.tokenRefreshFailed = false;
      })
      .addCase(refreshTokenThunk.fulfilled, (state, action) => {
        state.isPending = false;
        state.isUserLogged = true;
        if (action.payload) {
          state.tokenIsRefreshing = true;
          state.account = action.payload.account;
        }
      })
      .addCase(refreshTokenThunk.rejected, (state, action) => {
        state.isPending = false;
        if (action.payload) {
          state.error = action.payload;
          state.isUserLogged = false;
          state.tokenRefreshFailed = true;
        } else {
          state.tokenRefreshFailed = true;
          state.unexpectedError = action.error;
        }
      })
      .addCase(checkInvitationLinkThunk.pending, ({ checkIsLinkExpired }) => {
        checkIsLinkExpired.error = null;
      })
      .addCase(checkInvitationLinkThunk.rejected, (state, action) => {
        if (action.payload) {
          state.checkIsLinkExpired.error = action.payload;
          state.checkIsLinkExpired.linkType = ELinkType.INVITE;
        } else {
          state.unexpectedError = action.error;
        }
      })
      .addCase(
        checkResetPasswordLinkThunk.pending,
        ({ checkIsLinkExpired }) => {
          checkIsLinkExpired.error = null;
        },
      )
      .addCase(checkResetPasswordLinkThunk.rejected, (state, action) => {
        if (action.payload) {
          state.checkIsLinkExpired.error = action.payload;
          state.checkIsLinkExpired.linkType = ELinkType.RESET;
        } else {
          state.unexpectedError = action.error;
        }
      });
  },
});

export const {
  isEmailSendFalse,
  refreshProfileInfoModalData,
  signOut,
  refreshCompleteRegistration,
  refreshLinkExpired,
} = AuthReducer.actions;
export default AuthReducer.reducer;
