import React, { ReactElement, Suspense, useMemo } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { RouteConfig } from '../../utils/RouterUtil';
import AuthorizedWrapper from '../AuthorizedWrapper';
import { DashboardUrl, LoginUrl, LeadsUrl } from '../../UrlsConfig';
import { useAppSelector } from '../../hooks/hooks';
import { AccountType } from '../../common/enums/account/account-type.enum';

const PageNotFound = React.lazy(() => import('../../pages/NotFound'));

const RouterComponent: React.FunctionComponent = () => {
  const { isUserLogged, account } = useAppSelector((state) => state.auth);

  const renderAuthRoutes = useMemo(() => {
    if (!RouteConfig || (RouteConfig && !RouteConfig.authList)) {
      return [];
    }

    const result: ReactElement[] = [];

    RouteConfig.authList.forEach((item) => {
      if (
        !item.role.length ||
        (account.accountType && item.role.includes(account.accountType))
      ) {
        result.push(
          <Route
            key={item.path}
            path={item.path}
            element={
              <AuthorizedWrapper>
                <Suspense fallback={null}>
                  <item.component key={item.path} />
                </Suspense>
              </AuthorizedWrapper>
            }
          />,
        );
      } else {
        result.push(
          <Route
            key={item.path}
            path={item.path}
            element={<Navigate to={DashboardUrl} />}
          />,
        );
      }
    });

    return [
      ...result,
      <Route
        key="/"
        path="/"
        element={
          <Navigate
            to={
              account.accountType === AccountType.Restricted
                ? LeadsUrl
                : DashboardUrl
            }
          />
        }
      />,
      <Route
        key="/login"
        path="/login"
        element={
          <Navigate
            to={
              account.accountType === AccountType.Restricted
                ? LeadsUrl
                : DashboardUrl
            }
          />
        }
      />,
      <Route
        key="*"
        path="*"
        element={
          <React.Suspense fallback={null}>
            <PageNotFound />
          </React.Suspense>
        }
      />,
    ];
  }, [account.accountType]);

  const renderNotAuthRoutes = useMemo(() => {
    if (!RouteConfig || (RouteConfig && !RouteConfig.notAuthList)) {
      return [];
    }

    const result = RouteConfig.notAuthList.map((item) => (
      <Route
        key={item.path}
        path={item.path}
        element={
          <Suspense fallback={null}>
            <item.component key={item.path} />
          </Suspense>
        }
      />
    ));

    return [
      ...result,
      <Route key="/" path="/" element={<Navigate to={LoginUrl} />} />,
      <Route key="*" path="*" element={<Navigate to={LoginUrl} />} />,
    ];
  }, []);

  return (
    <Routes>{isUserLogged ? renderAuthRoutes : renderNotAuthRoutes}</Routes>
  );
};

export default RouterComponent;
