export const HomeUrl = '/';
export const LoginUrl = '/login';
export const DashboardUrl = '/dashboard';
export const AccountsUrl = '/accounts';
export const AffiliatesUrl = '/affiliates';
export const MyAgentsUrl = '/my-agents';
export const LeadsUrl = '/leads';
export const UncategorizedLeadsUrl = '/new-leads';
export const LeadsImportUrl = '/leads-import';
export const SettingsUrl = '/settings';
export const ForgotPasswordUrl = '/forgot-password';
export const ResetPasswordUrl = '/reset-password';
export const CompleteRegisrationUrl = '/complete-registration';
export const NotFoundUrl = '/not-found';
export const FoldersUrl = '/folders';
export const FolderDetailsUrl = '/folders/:folderId';
export const ReportsUrl = '/reports';

export const linksForSignOut = [CompleteRegisrationUrl, ResetPasswordUrl];
