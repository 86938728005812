import React, { useEffect, useRef } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { TranslationResult } from '../../service/types';
import { Props } from './types';
import { EMessageType } from '../../service/enums';
import { i18NParser } from '../../i18n/utils';
import Button from '../Button';

const InfoBox: React.FC<Props> = (props) => {
  const { textKey, className, type, params = {}, onClose } = props;
  const { t, i18n } = useTranslation();
  const infoBoxRef = useRef<HTMLDivElement | null>(null);
  const { title, message } = i18n.exists(`${type}.${textKey}`)
    ? (t(`${type}.${textKey}`, {
        returnObjects: true,
        ...params,
      }) as TranslationResult)
    : { title: i18NParser(textKey), message: '' };

  useEffect(() => {
    if (infoBoxRef.current && type === EMessageType.ERROR) {
      infoBoxRef.current.scrollIntoView();
    }

    if (infoBoxRef.current) {
      infoBoxRef.current.addEventListener('click', onClose);

      return () => {
        infoBoxRef.current?.removeEventListener('click', onClose);
      };
    }
  }, [infoBoxRef, params, onClose]);

  return (
    <div className="banner" ref={infoBoxRef}>
      <div className={classNames('banner-container', type, className)}>
        <div className="info-header" ref={infoBoxRef}>
          <Button className="btn-icon" onClick={onClose}>
            <span className="icon icon-x-close"></span>
          </Button>
        </div>
        <div className="info-body">
          <span className="icon"></span>
          <h2>{title}</h2>
          {message && <span className="description">{message}</span>}
        </div>
        <div className="info-footer">
          <Button className="btn-primary" onClick={onClose}>
            Got It
          </Button>
        </div>
      </div>
    </div>
  );
};

export default InfoBox;
