export enum AccountErrorCodes {
  AccountNotActive = "account_not_active",
  AccountNotFound = "account_not_found",
  AccountWithEmailAlreadyExist = "account_with_email_already_exist",
  InvitationLinkAlreadyUsed = "invitation_link_already_used",
  AccountAlreadyActivated = "account_already_activated",
  InvalidAccountStatus = "invalid_account_status",
  AccountAccessDenied = "account_access_denied",
  PdfTemplateNotFound = "pdf_template_not_found"
}
