import { createAsyncThunk } from '@reduxjs/toolkit';

import { IErrorDTO } from './../../common/interfaces/dto/common/ierror.interface';
import { ILoginResponseDTO } from '../../common/interfaces/dto/auth/ilogin-response.interfaces';
import { IApiResponseGenericDTO } from '../../common/interfaces/dto/common/iapi-response.interface';
import { AuthRoutes } from '../../common/enums/routes/auth-routes.enum';
import { IRecoverPasswordRequestDTO } from '../../common/interfaces/dto/auth/irecover-password-request.interface';
import { IResetPasswordRequestDTO } from '../../common/interfaces/dto/auth/ireset-password-request.interface';
import { IAccountCompleteRegistrationDTO } from '../../common/interfaces/dto/account/iaccount-complete-registration.interface';
import { AccountRoutes } from '../../common/enums/routes/account-routes.enum';
import { ILoginRequestData } from '../types/authTypes';
import { httpAccount, httpAuth } from '../../api';
import {
  getAccessToken,
  getRefreshToken,
  removeTokens,
  setStorage,
} from '../../utils/StorageUtil';
import { EToken } from '../types';

export const signInThunk = createAsyncThunk<
  ILoginResponseDTO,
  ILoginRequestData,
  { rejectValue: IErrorDTO }
>(
  'authReducer/signInThunk',
  async (userData: ILoginRequestData, { rejectWithValue }) => {
    const response = await fetch(`${httpAuth}/${AuthRoutes.Login}`, {
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
      },
      method: 'POST',
      body: JSON.stringify(userData),
    });

    const jsonData = await response.json();

    const successResult = jsonData as ILoginResponseDTO;

    const errorResult = jsonData as IApiResponseGenericDTO<ILoginResponseDTO>;

    if (
      errorResult.error &&
      (errorResult.statusCode !== 200 || !errorResult.success)
    ) {
      return rejectWithValue(errorResult.error);
    }

    const { access } = successResult;

    setStorage(EToken.TOKENS, {
      accessToken: access.accessToken,
      refreshToken: access.refreshToken || '',
    });

    return successResult;
  },
);

export const forgotPasswordThunk = createAsyncThunk<
  boolean,
  IRecoverPasswordRequestDTO,
  { rejectValue: IErrorDTO }
>('authReducer/forgotPasswordThunk', async (data, { rejectWithValue }) => {
  const response = await fetch(`${httpAuth}/${AuthRoutes.PasswordRecovery}`, {
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
    },
    method: 'POST',
    body: JSON.stringify(data),
  });

  const jsonData = await response.json();

  const successResult = jsonData as boolean;

  const errorResult = jsonData as IApiResponseGenericDTO<boolean>;

  if (
    errorResult.error &&
    (errorResult.statusCode !== 200 || !errorResult.success)
  ) {
    return rejectWithValue(errorResult.error);
  }

  return successResult;
});

export const refreshTokenThunk = createAsyncThunk<
  ILoginResponseDTO | undefined,
  boolean | undefined,
  { rejectValue: IErrorDTO | null }
>('authReducer/refreshTokenThunk', async (_, { rejectWithValue }) => {
  const accessToken = getAccessToken();
  const refreshToken = getRefreshToken();

  if (!accessToken) {
    return rejectWithValue(null);
  }

  const response = await fetch(`${httpAuth}/${AuthRoutes.RefreshToken}`, {
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
    },
    method: 'PUT',
    body: JSON.stringify({
      token: refreshToken,
    }),
  });

  const jsonData = await response.json();

  const successResult = jsonData as ILoginResponseDTO;

  const errorResult = jsonData as IApiResponseGenericDTO<ILoginResponseDTO>;

  if (
    errorResult.error &&
    (errorResult.statusCode !== 200 || !errorResult.success)
  ) {
    removeTokens();
    return rejectWithValue(errorResult.error);
  }

  const { access } = successResult;
  setStorage(EToken.TOKENS, {
    accessToken: access.accessToken,
    refreshToken: access.refreshToken || '',
  });

  return successResult;
});

export const resetPasswordThunk = createAsyncThunk<
  boolean,
  IResetPasswordRequestDTO,
  { rejectValue: IErrorDTO }
>('authReducer/resetPasswordThunk', async (data, { rejectWithValue }) => {
  const response = await fetch(`${httpAuth}/${AuthRoutes.ResetPassword}`, {
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
    },
    method: 'PUT',
    body: JSON.stringify(data),
  });

  const jsonData = await response.json();

  const successResult = jsonData as boolean;

  const errorResult = jsonData as IApiResponseGenericDTO<boolean>;

  if (
    errorResult.error &&
    (errorResult.statusCode !== 200 || !errorResult.success)
  ) {
    return rejectWithValue(errorResult.error);
  }

  return successResult;
});

export const completeRegistrationThunk = createAsyncThunk<
  boolean,
  IAccountCompleteRegistrationDTO,
  { rejectValue: IErrorDTO }
>(
  'authReducer/completeRegistrationThunk',

  async (data, { rejectWithValue }) => {
    const response = await fetch(
      `${httpAccount}/${AccountRoutes.CompleteRegistration}`,
      {
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
        },
        method: 'POST',
        body: JSON.stringify(data),
      },
    );

    const jsonData = await response.json();

    const successResult = jsonData as boolean;

    const errorResult = jsonData as IApiResponseGenericDTO<boolean>;

    if (
      errorResult.error &&
      (errorResult.statusCode !== 200 || !errorResult.success)
    ) {
      return rejectWithValue(errorResult.error);
    }

    return successResult;
  },
);

export const checkInvitationLinkThunk = createAsyncThunk<
  boolean,
  { token: string },
  { rejectValue: IErrorDTO }
>('authReducer/checkInvitationLinkThunk', async (data, { rejectWithValue }) => {
  const response = await fetch(`${httpAuth}/${AuthRoutes.TokenValidInvite}`, {
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
    },
    method: 'PUT',
    body: JSON.stringify(data),
  });

  const jsonData = await response.json();

  const successResult = jsonData as boolean;

  const errorResult = jsonData as IApiResponseGenericDTO<boolean>;

  if (
    errorResult.error &&
    (errorResult.statusCode !== 200 || !errorResult.success)
  ) {
    return rejectWithValue(errorResult.error);
  }

  return successResult;
});

export const checkResetPasswordLinkThunk = createAsyncThunk<
  boolean,
  { token: string },
  { rejectValue: IErrorDTO }
>(
  'authReducer/checkResetPasswordLinkThunk',
  async (data, { rejectWithValue }) => {
    const response = await fetch(
      `${httpAuth}/${AuthRoutes.TokenValidPassword}`,
      {
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
        },
        method: 'PUT',
        body: JSON.stringify(data),
      },
    );

    const jsonData = await response.json();

    const successResult = jsonData as boolean;

    const errorResult = jsonData as IApiResponseGenericDTO<boolean>;

    if (
      errorResult.error &&
      (errorResult.statusCode !== 200 || !errorResult.success)
    ) {
      return rejectWithValue(errorResult.error);
    }

    return successResult;
  },
);
