type Pattern = {
  toReplace: string;
  replaceTo: string;
};

export const i18NParser = (
  message: string,
  patterns: Pattern[] = [{ toReplace: '_', replaceTo: ' ' }],
): string => {
  let messageCopy = message;
  let formattedMessage = message;

  patterns.forEach((pattern) => {
    if (messageCopy.includes(pattern.toReplace)) {
      formattedMessage = messageCopy
        .split(pattern.toReplace)
        .join(pattern.replaceTo);

      messageCopy = formattedMessage;
    }
  });

  if (formattedMessage !== message) {
    const firstLetter = message[0].toUpperCase();

    return firstLetter + formattedMessage.slice(1);
  }

  return formattedMessage;
};
