import { createSlice } from '@reduxjs/toolkit';

import { TIntegrationsReducerInitialState } from '../types/settingsTypes';
import {
  createIntegrationThunk,
  deleteIntegrationThunk,
  generateIntegrationSecretThunk,
  getDuplicatesThunk,
  getIntegrationListThunk,
  updateDuplicatesThunk,
  updateIntegrationThunk,
} from '../thunks/settingsThunk';

export const initialState: TIntegrationsReducerInitialState = {
  getIntegrations: {
    integrations: [],
    isFetching: false,
    error: null,
  },

  createIntegration: {
    integration: null,
    isLoading: false,
    isCreated: false,
    error: null,
  },

  updateIntegration: {
    integration: null,
    isLoading: false,
    isUpdated: false,
    error: null,
  },

  deleteIntegration: {
    isLoading: false,
    isDeleted: false,
    error: null,
  },

  generateIntegrationSecret: {
    integration: null,
    isLoading: false,
    isGenerate: false,
    error: null,
  },

  getUpdateDuplicates: {
    duplicates: null,
    isLoading: false,
    isUpdated: false,
    error: null,
  },

  unexpectedError: null,
};

const SettingsReducer = createSlice({
  name: 'Settings',
  initialState,
  reducers: {
    refreshSettingsData(state) {
      state.createIntegration.isCreated = false;
      state.updateIntegration.isUpdated = false;
      state.deleteIntegration.isDeleted = false;

      state.createIntegration.isLoading = false;
      state.updateIntegration.isLoading = false;
      state.deleteIntegration.isLoading = false;
      // state.refreshIntegrations.isLoading = false;

      state.generateIntegrationSecret.isLoading = false;
      state.generateIntegrationSecret.isGenerate = false;
      state.getIntegrations = initialState.getIntegrations;
    },
    resetGetSettingsError({
      getIntegrations,
      createIntegration,
      updateIntegration,
      deleteIntegration,
      // refreshIntegrations,
      generateIntegrationSecret,
    }) {
      getIntegrations.error = null;
      createIntegration.error = null;
      updateIntegration.error = null;
      deleteIntegration.error = null;
      // refreshIntegrations.error = null;
      generateIntegrationSecret.error = null;
    },
    resetIntegrationModal(state) {
      state.createIntegration = initialState.createIntegration;
      state.updateIntegration = initialState.updateIntegration;
    },
    resetDeleteIntegration(state) {
      state.deleteIntegration = initialState.deleteIntegration;
    },
    resetUpdateDuplicates(state) {
      state.getUpdateDuplicates.isUpdated = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getIntegrationListThunk.pending, ({ getIntegrations }) => {
        getIntegrations.isFetching = true;
        getIntegrations.integrations = [];
      })
      .addCase(
        getIntegrationListThunk.fulfilled,
        ({ getIntegrations }, action) => {
          getIntegrations.isFetching = false;
          if (action.payload) {
            getIntegrations.integrations = action.payload.integrations;
          }
        },
      )
      .addCase(getIntegrationListThunk.rejected, (state, action) => {
        if (state.getIntegrations.isFetching) {
          state.getIntegrations.isFetching = false;
        }
        if (action.payload) {
          state.getIntegrations.error = action.payload;
        } else {
          state.unexpectedError = action.error;
        }
      })

      .addCase(createIntegrationThunk.pending, ({ createIntegration }) => {
        createIntegration.isLoading = true;
      })
      .addCase(
        createIntegrationThunk.fulfilled,
        ({ createIntegration }, action) => {
          createIntegration.isLoading = false;
          if (action.payload) {
            createIntegration.isCreated = true;
            createIntegration.integration = action.payload;
          }
        },
      )
      .addCase(createIntegrationThunk.rejected, (state, action) => {
        if (state.createIntegration.isLoading) {
          state.createIntegration.isLoading = false;
        }
        if (action.payload) {
          state.createIntegration.error = action.payload;
        } else {
          state.unexpectedError = action.error;
        }
      })

      .addCase(updateIntegrationThunk.pending, ({ updateIntegration }) => {
        updateIntegration.isLoading = true;
      })
      .addCase(
        updateIntegrationThunk.fulfilled,
        ({ updateIntegration }, action) => {
          updateIntegration.isLoading = false;
          if (action.payload) {
            updateIntegration.isUpdated = true;
            updateIntegration.integration = action.payload;
          }
        },
      )
      .addCase(updateIntegrationThunk.rejected, (state, action) => {
        if (state.updateIntegration.isLoading) {
          state.updateIntegration.isLoading = false;
        }
        if (action.payload) {
          state.updateIntegration.error = action.payload;
        } else {
          state.unexpectedError = action.error;
        }
      })

      .addCase(deleteIntegrationThunk.pending, ({ deleteIntegration }) => {
        deleteIntegration.isLoading = true;
      })
      .addCase(deleteIntegrationThunk.fulfilled, ({ deleteIntegration }) => {
        deleteIntegration.isLoading = false;
        deleteIntegration.isDeleted = true;
      })
      .addCase(deleteIntegrationThunk.rejected, (state, action) => {
        if (state.deleteIntegration.isLoading) {
          state.deleteIntegration.isLoading = false;
        }
        if (action.payload) {
          state.deleteIntegration.error = action.payload;
        } else {
          state.unexpectedError = action.error;
        }
      })

      .addCase(generateIntegrationSecretThunk.pending, (state) => {
        state.generateIntegrationSecret.isLoading = true;
        state.generateIntegrationSecret =
          initialState.generateIntegrationSecret;
      })
      .addCase(generateIntegrationSecretThunk.fulfilled, (state, action) => {
        state.generateIntegrationSecret.isLoading = false;
        if (action.payload) {
          state.generateIntegrationSecret.isGenerate = true;
          state.generateIntegrationSecret.integration = action.payload;

          const integrationToReplaceIndex =
            state.getIntegrations.integrations.findIndex(
              (element) => element.id === action.payload?.id,
            );

          if (integrationToReplaceIndex !== -1) {
            const integrationsCopy = [...state.getIntegrations.integrations];
            integrationsCopy.splice(
              integrationToReplaceIndex,
              1,
              action.payload,
            );
            state.getIntegrations.integrations = integrationsCopy;
          }
        }
      })
      .addCase(generateIntegrationSecretThunk.rejected, (state, action) => {
        if (state.generateIntegrationSecret.isLoading) {
          state.generateIntegrationSecret.isLoading = false;
        }
        if (action.payload) {
          state.generateIntegrationSecret.error = action.payload;
        } else {
          state.unexpectedError = action.error;
        }
      })

      .addCase(getDuplicatesThunk.pending, (state) => {
        state.getUpdateDuplicates.isLoading = true;
        state.getUpdateDuplicates = initialState.getUpdateDuplicates;
      })
      .addCase(getDuplicatesThunk.fulfilled, (state, action) => {
        state.getUpdateDuplicates.isLoading = false;
        if (action.payload) {
          state.getUpdateDuplicates.duplicates = action.payload;
        }
      })
      .addCase(getDuplicatesThunk.rejected, (state, action) => {
        if (state.getUpdateDuplicates.isLoading) {
          state.getUpdateDuplicates.isLoading = false;
        }
        if (action.payload) {
          state.getUpdateDuplicates.error = action.payload;
        } else {
          state.unexpectedError = action.error;
        }
      })

      .addCase(updateDuplicatesThunk.pending, (state) => {
        state.getUpdateDuplicates.isLoading = true;
      })
      .addCase(updateDuplicatesThunk.fulfilled, (state, action) => {
        state.getUpdateDuplicates.isLoading = false;
        if (action.payload) {
          state.getUpdateDuplicates.isUpdated = true;
          state.getUpdateDuplicates.duplicates = action.payload;
        }
      })
      .addCase(updateDuplicatesThunk.rejected, (state, action) => {
        if (state.getUpdateDuplicates.isLoading) {
          state.getUpdateDuplicates.isLoading = false;
        }
        if (action.payload) {
          state.getUpdateDuplicates.error = action.payload;
        } else {
          state.unexpectedError = action.error;
        }
      });
  },
});

export const {
  refreshSettingsData,
  resetGetSettingsError,
  resetIntegrationModal,
  resetDeleteIntegration,
  resetUpdateDuplicates,
} = SettingsReducer.actions;

export default SettingsReducer.reducer;
