export enum SettingRoutes {
  BasePrefix = "settings",
  GetDuplicatesSettings = "duplicates/:accountId",
  UpdateDuplicatesSettings = "duplicates/:accountId",
  GetIntegrations = "integrations",
  CreateIntegration = "integrations",
  UpdateIntegration = "integrations/:integrationId",
  GenerateIntegrationSecret = "integrations/:integrationId/secret",
  DeleteIntegration = "integrations/:integrationId"
}
