import { EToken } from '../redux/types';

export const setStorage = (
  key: string,
  initialValue: { [key: string]: string } | string | boolean,
): void => {
  window.localStorage.setItem(key, JSON.stringify(initialValue));
};

export const getStorage = (
  key: string,
): { [key: string]: string } | string | boolean | undefined => {
  const storageDataByKey = window.localStorage.getItem(key);
  if (!storageDataByKey) {
    return undefined;
  }
  let result = '';
  try {
    result = JSON.parse(storageDataByKey);
  } catch (e) {
    if (
      e instanceof SyntaxError &&
      (typeof storageDataByKey === 'string' ||
        typeof storageDataByKey === 'boolean')
    ) {
      result = storageDataByKey;
    } else {
      throw e;
    }
  }
  return result;
};

export const removeStorage = (key: string): void => {
  window.localStorage.removeItem(key);
};

export const checkStorage = (key: string) => {
  const storageDataByKey = window.localStorage.getItem(key);
  return !!storageDataByKey;
};

export const getAccessToken = () => {
  const tokens = getStorage(EToken.TOKENS) as Record<string, string>;
  return tokens ? tokens[EToken.ACCESS_TOKEN] : undefined;
};

export const getRefreshToken = () => {
  const tokens = getStorage(EToken.TOKENS) as Record<string, string>;
  return tokens ? tokens[EToken.REFRESH_TOKEN] : undefined;
};

export const removeTokens = (): void => {
  removeStorage(EToken.TOKENS);
};
