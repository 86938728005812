import { useCallback, useEffect, useMemo, useState } from 'react';
import { ActionCreatorWithoutPayload } from '@reduxjs/toolkit';
import { IErrorDTO } from '../common/interfaces/dto/common/ierror.interface';
import { useAppDispatch } from './hooks';
import { CommonErrorCodes } from '../common/enums/error-codes/common-error-codes.enum';

export const useError = (
  resetErrors: ActionCreatorWithoutPayload | ActionCreatorWithoutPayload[],
  ...errors: (IErrorDTO | null)[]
): { errorFromHook: IErrorDTO | null; onRessetError: () => void } => {
  const dispatch = useAppDispatch();
  const [apiError, setApiError] = useState<IErrorDTO | null>(null);

  const memoizedErrors = useMemo(() => errors, [...errors]);

  useEffect(() => {
    return () => {
      if (Array.isArray(resetErrors)) {
        resetErrors.forEach((reset) => dispatch(reset()));
      } else {
        dispatch(resetErrors());
      }
    };
  }, []);

  useEffect(() => {
    if (memoizedErrors.length) {
      let isError = false;
      memoizedErrors.forEach((error) => {
        if (!!error && error.errorCode !== CommonErrorCodes.FieldIsInvalid) {
          isError = true;
          setApiError(error);
          return;
        }
      });
      if (!isError) {
        setApiError(null);
      }
    }
  }, [memoizedErrors]);

  const onRessetError = useCallback(() => {
    setApiError(null);

    if (Array.isArray(resetErrors)) {
      resetErrors.forEach((reset) => dispatch(reset()));
    } else {
      dispatch(resetErrors());
    }
  }, [resetErrors]);

  return { errorFromHook: apiError, onRessetError };
};
