export enum TokenErrorCodes {
  ProblemWithInviteToken = "problem_with_invite_token",
  ProblemWithAccessToken = "problem_with_access_token",
  ProblemWithRefreshToken = "problem_with_refresh_token",
  ProblemWithResetPasswordToken = "problem_with_reset_password_token",
  ProblemWithDownloaddToken = "problem_with_download_token",
  RefreshTokenExpired = "refresh_token_expired",
  ResetPasswordTokenExpired = "reset_password_token_expired",
  TokenConflict = "token_conflict"
}
