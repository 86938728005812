import React, { useState, useMemo } from 'react';
import { NavLink } from 'react-router-dom';
import { useNavigate } from 'react-router';
import classNames from 'classnames';

import Button from '../Button';

import { signOut } from '../../redux/reducers/authReducer';
import { useAppDispatch, useAppSelector } from '../../hooks/hooks';
import { initPagination, navbarArr } from './utils';
import logo from '../../assets/img/logo.svg';
import { FoldersUrl } from '../../UrlsConfig';

import './style.scss';
import { AccountType } from '../../common/enums/account/account-type.enum';

const Header = () => {
  const [openedSubMenu, setOpenedSubMenu] = useState<number | null>(null);
  const dispatch = useAppDispatch();
  const { account } = useAppSelector(({ auth }) => auth);
  const {
    getAllFolders: { folders },
  } = useAppSelector(({ folders }) => folders);

  const navigate = useNavigate();

  const preparedNavbarArr = useMemo(() => {
    let nbArr = navbarArr;
    if (
      account.accountType === AccountType.MainAccount &&
      !account.showReports
    ) {
      nbArr = nbArr.filter((el) => el.id !== 8);
    }
    return nbArr.map((navbarElement) => {
      if (navbarElement.id === 5) {
        return {
          ...navbarElement,
          subMenu: folders.map((folder) => {
            return {
              id: folder.id,
              title: folder.name,
              linkAddress: `${FoldersUrl}/${folder.id}${initPagination}`,
              leadsCount: folder.leadsCount,
            };
          }),
        };
      }

      return navbarElement;
    });
  }, [account, folders]);

  const logout = () => {
    dispatch(signOut());
  };

  const openSubMenu = (id: number) => {
    if (id === 5) {
      navigate(FoldersUrl);
    }

    if (openedSubMenu === id) {
      setOpenedSubMenu(null);
      return;
    }
    setOpenedSubMenu(id);
  };

  return (
    <>
      <header className="header">
        <div className="logo-wrap">
          <NavLink to="/" className="navbar_item">
            <img src={logo} alt="Hubblelms" />
          </NavLink>
          <span className="version">v1.0.0</span>
        </div>
        <div className="navbar">
          <ul className="navbar_list">
            {preparedNavbarArr.map((el) => {
              if (
                !el.role.length ||
                (account.accountType && el.role.includes(account.accountType))
              ) {
                return el.subMenu?.length ? (
                  <li key={el.id}>
                    <div
                      className="navbar_item"
                      onClick={() => openSubMenu(el.id)}
                    >
                      <span className={`icon ${el.className}`}></span>
                      <span className="title">{el.title}</span>
                      <span
                        className={classNames('icon', {
                          'icon-chevron-down': el.subMenu,
                          'icon-chevron-up':
                            openedSubMenu && openedSubMenu === el.id,
                        })}
                      ></span>
                    </div>

                    {openedSubMenu === el.id && (
                      <ul className="navbar_list int_list">
                        {el.subMenu.map((subEl) => {
                          return (
                            <li key={subEl.id}>
                              <NavLink
                                to={subEl.linkAddress || ''}
                                className="navbar_item"
                              >
                                <span className="title">{subEl.title}</span>
                                {subEl.leadsCount && (
                                  <span className="count">
                                    {subEl.leadsCount}
                                  </span>
                                )}
                              </NavLink>
                            </li>
                          );
                        })}
                      </ul>
                    )}
                  </li>
                ) : (
                  <li key={el.id}>
                    <NavLink to={el.linkAddress || ''} className="navbar_item">
                      <span className={`icon ${el.className}`}></span>
                      <span className="title">{el.title}</span>
                    </NavLink>
                  </li>
                );
              } else {
                return <React.Fragment key={el.id}></React.Fragment>;
              }
            })}
          </ul>
        </div>
        <div className="profile-wrap">
          <div className="name-wrap">
            <span className="name">{account.name}</span>
            <span className="role">{account.accountType}</span>
          </div>
          <Button className="btn-icon" onClick={logout}>
            <span className="icon icon-log-out"></span>
          </Button>
        </div>
      </header>
    </>
  );
};

export default Header;
