export const DOTS = '...';
export const DEFAULT_SIBLING_COUNT = 1;

export const initPagination = {
  pageSize: 100,
  pageIndex: 0,
};

export const convertToQueryString = <T extends object>(params: T): string => {
  /**
   * if you don't wont to see object value in a link params, just name it "metaData"
   */
  return Object.entries(params).reduce((acc, [key, value]) => {
    if (
      (!value && value !== 0) ||
      (Array.isArray(value) && value.length === 0) ||
      key === 'metaData'
    ) {
      return acc;
    }

    const divider = acc === '' ? '' : '&';

    return acc + `${divider}${key}=${value}`;
  }, '');
};

export const checkFileSize = (files: File[], size: number) => {
  if (!files?.length) {
    return true;
  }

  return !files[0] || (files[0] && files[0].size <= size);
};

export const checkFileType = (files: File[], type: string) => {
  if (!files?.length) {
    return true;
  }

  return !files[0] || (files[0] && files[0].type === type);
};

export const getFileType = (base64Data: string) =>
  base64Data.split(';')[0].split('/')[1];

export const inputDateFormatting = (value: string) => {
  return value.toLowerCase().trim();
};

export const waitMs = (interval: number) =>
  new Promise<void>((res) => {
    setTimeout(() => {
      res();
    }, interval);
  });
