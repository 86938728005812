import { createSlice } from '@reduxjs/toolkit';
import { TPdfTemplateReducerInitialState } from '../types/pdfTemplateTypes';
import {
  applyTemplateToOtherAccountsThunk,
  exportTemplateThunk,
  getConfigureTemplatesThunk,
  getPdfTemplateThunk,
  updateTemplateThunk,
} from '../thunks/pdfTemplateThunk';
import { convertTemplatesToSelectOptions } from '../../utils/SelectOptionsUtil';

export const initialState: TPdfTemplateReducerInitialState = {
  getPdfTemplate: {
    template: null,
    isFetching: false,
    error: null,
  },

  getConfigureTemplates: {
    templates: [],
    isFetching: false,
    error: null,
  },

  assignTemplate: {
    isLoading: false,
    isReassign: false,
    isUpdateConfiguration: false,
    error: null,
  },

  updateTemplate: {
    isLoading: false,
    isUpdated: false,
    error: null,
  },

  getPreview: {
    file: null,
    isLoading: false,
    error: null,
  },

  unexpectedError: null,
};

const PdfTemplateReducer = createSlice({
  name: 'PdfTemplate',
  initialState,
  reducers: {
    refreshPdfTemplateData(state) {
      state.assignTemplate.isReassign = false;
      state.assignTemplate.isUpdateConfiguration = false;
      state.updateTemplate.isUpdated = false;
    },
    resetPreviewFile(state) {
      state.getPreview = initialState.getPreview;
    },
    resetPdfTemplateError({
      assignTemplate,
      updateTemplate,
      getConfigureTemplates,
      getPdfTemplate,
      getPreview,
    }) {
      getConfigureTemplates.error = null;
      assignTemplate.error = null;
      updateTemplate.error = null;
      getPdfTemplate.error = null;
      getPreview.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getPdfTemplateThunk.pending, ({ getPdfTemplate }) => {
        getPdfTemplate.isFetching = true;
        getPdfTemplate.template = null;
      })
      .addCase(getPdfTemplateThunk.fulfilled, ({ getPdfTemplate }, action) => {
        if (action.payload) {
          getPdfTemplate.template = action.payload;
          getPdfTemplate.isFetching = false;
        }
      })
      .addCase(getPdfTemplateThunk.rejected, (state, action) => {
        if (state.getPdfTemplate.isFetching) {
          state.getPdfTemplate.isFetching = false;
        }
        if (action.payload) {
          // Since we passed in `MyKnownError` to `rejectValue` in `updateAccount`, the type information will be available here.
          state.getPdfTemplate.error = action.payload;
        } else {
          state.unexpectedError = action.error;
        }
      })
      .addCase(
        getConfigureTemplatesThunk.pending,
        ({ getConfigureTemplates }) => {
          getConfigureTemplates.isFetching = true;
          getConfigureTemplates.templates = [];
        },
      )
      .addCase(
        getConfigureTemplatesThunk.fulfilled,
        ({ getConfigureTemplates }, action) => {
          if (action.payload) {
            getConfigureTemplates.templates = convertTemplatesToSelectOptions(
              action.payload.templates,
            );
            getConfigureTemplates.isFetching = false;
          }
        },
      )
      .addCase(getConfigureTemplatesThunk.rejected, (state, action) => {
        if (state.getConfigureTemplates.isFetching) {
          state.getConfigureTemplates.isFetching = false;
        }
        if (action.payload) {
          // Since we passed in `MyKnownError` to `rejectValue` in `updateAccount`, the type information will be available here.
          state.getConfigureTemplates.error = action.payload;
        } else {
          state.unexpectedError = action.error;
        }
      })
      .addCase(
        applyTemplateToOtherAccountsThunk.pending,
        ({ assignTemplate }) => {
          assignTemplate.isLoading = true;
          assignTemplate.isReassign = false;
        },
      )
      .addCase(
        applyTemplateToOtherAccountsThunk.fulfilled,
        ({ assignTemplate }, action) => {
          if (action.payload) {
            if (action.meta.arg.metaData === 'current') {
              assignTemplate.isUpdateConfiguration = action.payload;
            } else {
              assignTemplate.isReassign = action.payload;
            }
          }
          assignTemplate.isLoading = false;
        },
      )
      .addCase(applyTemplateToOtherAccountsThunk.rejected, (state, action) => {
        if (state.assignTemplate.isLoading) {
          state.assignTemplate.isLoading = false;
        }
        if (action.payload) {
          // Since we passed in `MyKnownError` to `rejectValue` in `updateAccount`, the type information will be available here.
          state.assignTemplate.error = action.payload;
        } else {
          state.unexpectedError = action.error;
        }
      })
      .addCase(updateTemplateThunk.pending, ({ updateTemplate }) => {
        updateTemplate.isLoading = true;
        updateTemplate.isUpdated = false;
      })
      .addCase(updateTemplateThunk.fulfilled, ({ updateTemplate }, action) => {
        if (action.payload) {
          updateTemplate.isUpdated = action.payload;
        }
        updateTemplate.isLoading = false;
      })
      .addCase(updateTemplateThunk.rejected, (state, action) => {
        if (state.updateTemplate.isLoading) {
          state.updateTemplate.isLoading = false;
        }
        if (action.payload) {
          // Since we passed in `MyKnownError` to `rejectValue` in `updateAccount`, the type information will be available here.
          state.updateTemplate.error = action.payload;
        } else {
          state.unexpectedError = action.error;
        }
      })
      .addCase(exportTemplateThunk.pending, ({ getPreview }) => {
        getPreview.isLoading = true;
        getPreview.file = null;
      })
      .addCase(exportTemplateThunk.fulfilled, ({ getPreview }, action) => {
        if (action.payload) {
          getPreview.file = action.payload;
        }
        getPreview.isLoading = false;
      })
      .addCase(exportTemplateThunk.rejected, (state, action) => {
        if (state.getPreview.isLoading) {
          state.getPreview.isLoading = false;
        }
        if (action.payload) {
          // Since we passed in `MyKnownError` to `rejectValue` in `updateAccount`, the type information will be available here.
          state.updateTemplate.error = action.payload;
        } else {
          state.unexpectedError = action.error;
        }
      });
  },
});

export const {
  refreshPdfTemplateData,
  resetPdfTemplateError,
  resetPreviewFile,
} = PdfTemplateReducer.actions;

export default PdfTemplateReducer.reducer;
