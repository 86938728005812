import { refreshTokenThunk } from '../redux/thunks/authThunk';
import { getBearerAccessToken } from '../api';

import { AsyncThunk, createAsyncThunk } from '@reduxjs/toolkit';

import { IErrorDTO } from '../common/interfaces/dto/common/ierror.interface';
import { getAccessToken } from './StorageUtil';
import { IApiResponseGenericDTO } from '../common/interfaces/dto/common/iapi-response.interface';
import { signOut } from '../redux/reducers/authReducer';
import { convertToQueryString } from './GeneralUtils';

export function thunkCreator<TResponse, TRequest extends object>(
  typePrefix: string,
  basePrefix: string,
  link: string,
  method: string,
): AsyncThunk<TResponse | null, TRequest, { rejectValue: IErrorDTO | null }> {
  return createAsyncThunk<
    TResponse | null,
    TRequest,
    { rejectValue: IErrorDTO | null }
  >(
    typePrefix,
    async (data: TRequest, { rejectWithValue, dispatch, signal }) => {
      const isBodyPresent = method !== 'GET' && method !== 'DELETE';
      let resultLink = link ? `/${link}` : '';
      const newData: TRequest & { metaData?: string } = Array.isArray(data)
        ? data
        : { ...data };

      if (link.includes(':')) {
        const indexStart = link.indexOf(':');
        let indexEnd = link.indexOf('/', indexStart);
        indexEnd = indexEnd > -1 ? indexEnd : link.length;
        const key = link.slice(indexStart + 1, indexEnd) as keyof TRequest;
        resultLink = `/${link.slice(0, indexStart)}${data[key] || ''}${
          indexEnd > -1 ? link.slice(indexEnd) : ''
        }`;
        delete data[key];
      }

      if (newData['metaData']) {
        delete newData['metaData'];
      }

      if (!isBodyPresent && Object.keys(data).length) {
        const params = convertToQueryString(data);
        resultLink = params ? `${resultLink}?${params}` : resultLink;
      }

      const bodyData = newData['data' as keyof TRequest] || newData;

      const fetchMethod = async () =>
        fetch(`${basePrefix}${resultLink}`, {
          method,
          headers: {
            Authorization: getBearerAccessToken(getAccessToken()),
            'Content-Type': 'application/json',
          },
          body: isBodyPresent
            ? bodyData && JSON.stringify(bodyData)
            : undefined,
          signal,
        });

      let response = await fetchMethod();

      if (method === 'DELETE' && response.status === 200) {
        return true as TResponse;
      }

      if (response.status === 401) {
        try {
          const refreshResult = await dispatch(refreshTokenThunk()).unwrap();
          if (refreshResult?.account.id) {
            response = await fetchMethod();
          }
        } catch (e) {
          console.error(e);
          return rejectWithValue(null);
        }
      }

      const jsonData = await response.json();

      const successResult = jsonData as TResponse;

      const errorResult = jsonData as IApiResponseGenericDTO<TResponse>;

      if (
        errorResult.error &&
        (errorResult.statusCode !== 200 || !errorResult.success)
      ) {
        if (errorResult.statusCode === 401) {
          dispatch(signOut());
          return null;
        }

        return rejectWithValue(errorResult.error);
      }

      return successResult;
    },
  );
}
