import { ISelect } from '../../service/types';
import { initSelectOption } from '../../utils/SelectOptionsUtil';

export const initAccountOption: ISelect = { value: '', label: 'All' };

export const leadsFilterDefaultValue: TFilterForm = {
  main: initAccountOption,
  sub: initAccountOption,
  agent: initAccountOption,
  state: initSelectOption,
  counties: [],
  filterAttachment: initAccountOption,
  dateRange: '',
};

export type TFilterForm = {
  main: ISelect;
  sub: ISelect;
  agent: ISelect;
  state: ISelect;
  counties: ISelect[];
  filterAttachment: ISelect;
  dateRange: string;
};
