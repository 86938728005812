import { httpLeads } from '../../api';
import { AccountType } from '../../common/enums/account/account-type.enum';
import { LeadType } from '../../common/enums/lead/lead-type.enum';
import { LeadRoutes } from '../../common/enums/routes/lead-routes.enum';
import { IAdminGetLeadReceivedStatisticalInfoDTO } from '../../common/interfaces/dto/lead/iadmin-get-lead-received-statistical-info.interface';
import { IGetLeadMetricsInfoDTO } from '../../common/interfaces/dto/lead/iget-lead-metrics-info.interface';
import { ILeadStatisticalInfoBySpecificStateDTO } from '../../common/interfaces/dto/lead/ilead-statistical-info-by-specific-state.interface';
import { ILeadStatisticalInfoByStatesDTO } from '../../common/interfaces/dto/lead/ilead-statistical-info-by-states.interface';
import { ITopLeadsInfoResponseDTO } from '../../common/interfaces/dto/lead/itop-leads-info-response.interface';
import { thunkCreator } from '../../utils/ThunkCreator';
import {
  IGetReceivedLeadsRequest,
  IGetMetricsLeadsRequest,
} from '../types/dashboardTypes';
import { IFilterLeadData } from '../types/leadsTypes';

export const getAdminLeadsStatisticInfoThunk = thunkCreator<
  IAdminGetLeadReceivedStatisticalInfoDTO,
  IGetReceivedLeadsRequest
>(
  'states/getAdminLeadsStatisticInfo',
  httpLeads,
  LeadRoutes.GetLeadsReceivedStatisticalInfo,
  'GET',
);

export const getLeadsMetricInfoThunk = thunkCreator<
  IGetLeadMetricsInfoDTO,
  IGetMetricsLeadsRequest
>(
  'states/getLeadsMetricInfo',
  httpLeads,
  LeadRoutes.GetLeadsMetricsInfo,
  'GET',
);

export const getTopLeadsInfoThunk = thunkCreator<
  ITopLeadsInfoResponseDTO,
  {
    accountType?: AccountType;
    dateFrom?: string;
    dateTo?: string;
    leadType?: LeadType;
  }
>('states/getTopLeadsInfo', httpLeads, LeadRoutes.GetTopLeadsInfo, 'GET');

export const getMapInfoThunk = thunkCreator<
  ILeadStatisticalInfoByStatesDTO,
  IFilterLeadData & { onlyUncategorized?: boolean }
>('states/getMapInfo', httpLeads, LeadRoutes.GetStatisticalInfoByStates, 'GET');

export const getStateInfoThunk = thunkCreator<
  ILeadStatisticalInfoBySpecificStateDTO,
  IFilterLeadData & { onlyUncategorized?: boolean; stateAbbr: string }
>(
  'states/getStateInfo',
  httpLeads,
  LeadRoutes.GetStatisticalInfoBySpecificStates,
  'GET',
);

export const getMapAffiliatesInfoThunk = thunkCreator<
  ILeadStatisticalInfoByStatesDTO,
  Record<string, undefined>
>(
  'states/getMapAffiliatesInfo',
  httpLeads,
  `${LeadRoutes.Stats}/${LeadRoutes.Affiliates}/${LeadRoutes.States}`,
  'GET',
);

export const getMapAgentInfoThunk = thunkCreator<
  ILeadStatisticalInfoByStatesDTO,
  Record<string, undefined>
>(
  'states/getMapAgentInfo',
  httpLeads,
  `${LeadRoutes.Stats}/${LeadRoutes.Agents}/${LeadRoutes.States}`,
  'GET',
);
