import { IDynamicCounterObject } from '../common/interfaces/dto/common/idynamic-counter-object.interface';
import { ICountyDTO } from '../common/interfaces/dto/state/icounty.interface';
import { IStateDTO } from '../common/interfaces/dto/state/istate.interface';

export const getStatesAbreviature = (states: IStateDTO[]) => {
  const result: Record<string, IStateDTO | undefined> = {};
  states.forEach((state) => (result[state.abbreviation.toUpperCase()] = state));
  return result as Record<string, IStateDTO>;
};

export const getCountiesMapByName = (counties: ICountyDTO[]) => {
  const result: Record<string, ICountyDTO | undefined> = {};
  counties.forEach(
    (county) => (result[formatCountyName(county.name)] = county),
  );
  return result as Record<string, ICountyDTO>;
};

export const getCountiesFormattedMap = (counties: IDynamicCounterObject) => {
  const result: IDynamicCounterObject = {};
  Object.entries(counties).forEach(
    ([name, val]) => (result[formatCountyName(name)] = val),
  );
  return result;
};

const formatCountyName = (name: string): string => {
  const str = name
    .replace(/The City and County of|County|Borough|Census Area|Parish/g, '')
    .replace(/City of (\w+ ?\w*)/g, '$1_City')
    .trim();
  // eslint-disable-next-line quotes
  return str.replaceAll(' ', '_').replaceAll("'", '_').replace('ñ', 'n');
};
