export enum UploadedLeadErrorCodes {
  IncorrectFileStructure = "incorrect_file_structure",
  LeadIsNotUnique = "lead_is_not_unique",
  LeadScanCodeIsNotUnique = "lead_scan_code_is_not_unique",
  InvalidLeadType = "invalid_lead_type",
  InvalidNumericType = "invalid_numeric_type",
  InvalidDateFormat = "invalid_date_format",
  InvalidStateAbbreviation = "invalid_state_abbreviation",
  InvalidCounty = "invalid_county",
  MissingRequiredField = "missing_required_field",
  RequestLeadIdsRequired = "request_lead_ids_required",
  LeadAccessDenied = "lead_access_denied"
}
